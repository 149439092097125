
// let Base
// if (w.AnkaPanAPI) {
//     Base = AnkaPanAPI.Base
// } else {
//     throw new Error('AnkaPanAPI could not be found')
// }

let Mesh
let MeshBasicMaterial
let MeshLambertMaterial
let LinearFilter
let NearestFilter
let Scene
let Texture
let TextureLoader
let ShaderMaterial
let Vector3
if (THREE) {
    Mesh = THREE.Mesh
    MeshBasicMaterial = THREE.MeshBasicMaterial
    MeshLambertMaterial = THREE.MeshLambertMaterial
    LinearFilter = THREE.LinearFilter
    NearestFilter = THREE.NearestFilter
    Texture = THREE.Texture
    Scene = THREE.Scene
    Vector3 = THREE.Vector3
    ShaderMaterial = THREE.ShaderMaterial
    TextureLoader = THREE.TextureLoader
} else {
    throw new Error('THREE.js could not be found')
}

export {
    Mesh,
    TextureLoader,
    MeshBasicMaterial,
    MeshLambertMaterial,
    LinearFilter,
    NearestFilter,
    Texture,
    Scene,
    ShaderMaterial,
    Vector3
}
