
import './ThirdParty/OBJLoader'
// import {Base} from './ImportHelper'
import { Mesh, MeshBasicMaterial, MeshLambertMaterial, Texture, LinearFilter, NearestFilter } from './ImportHelper'

class Tile extends Mesh {
    constructor (geometry, xIndex, yIndex, zIndex) {
        super(geometry, new MeshBasicMaterial({ color: 0x000000 }))
        this.material.transparent = true
        this.material.opacity = 0
        this._imgMaterial = new MeshLambertMaterial({ depthTest: false })
        this._invisibleMaterial = this.material
        this._waitingImage = false
        this._xIndex = xIndex
        this._yIndex = yIndex
        this._zIndex = zIndex
        this._lastImageLevel = -1
        this._state = 0
        this._currentID = Number.MIN_SAFE_INTEGER
        this.onBeforeRender = this._onBeforeRender
    }

    _onBeforeRender () {
        this.visibleToCamera = true
    }

    getTileIndexAsString () {
        return this._xIndex + '-' + this._yIndex + '-' + this._zIndex
    }

    setRenderFrameID (id) {
        if (this._currentID !== id) {
            this._currentID = id
            this.reset()
        }
    }

    setZoom (zoom) {
        this._zIndex = zoom
    }

    setImage (image, zoom) {
        let material = this._imgMaterial
        let ima = new Image()
        ima.src = 'data:image/jpeg;base64,' + image
        let texture = material.map
        if (!texture) {
            texture = new Texture(ima)
        } else {
            texture.image = ima
        }

        texture.generateMipmaps = false
        texture.minFilter = LinearFilter
        texture.magFilter = NearestFilter
        texture.needsUpdate = true
        material.needsUpdate = true
        material.map = texture
        this._hasImage = true
        this._lastImageLevel = zoom
        this._waitingImage = false
        this.material = material
    }

    get waitingImage () {
        return this._waitingImage
    }

    set waitingImage (b) {
        this._waitingImage = b
    }

    reset () {
        this._waitingImage = false
        this._lastImageLevel = -1
        this.material = this._invisibleMaterial
        this.visibleToCamera = false
    }

    switchToImageMaterial () {
        this.material = this._imgMaterial
    }

    switchToInvisibleMaterial () {
        this.material = this._invisibleMaterial
    }

    hide () {
        // this.visible = false
    }

    show () {
        // this.visible = true
    }
}

export { Tile }
