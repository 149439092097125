import { PanoGL } from './PanoGL'
import { TilePlugin } from './tile/TilePlugin'
import { Utils } from './Utils'
import { CursorManager } from './CursorManager'
import { Mesh, Scene, Vector3, ShaderMaterial, TextureLoader } from './tile/ImportHelper';

// const PANO_STATES = {
//     DEFAULT:'DEFAULT',
//     VIDEO_PLAY:'VIDEO_PLAY',

// }

var RGBToHex = function (r, g, b) {
    var bin = r << 16 | g << 8 | b
    return bin
}

class PanoGLV2 extends PanoGL {
    constructor(options) {
        super(options)
        this._options = options
        this._resizeMaterials = []
        super.setPlugin(new TilePlugin({ service: options.tileService }))
    }

    init() {
        super.init()
        CursorManager.addDiv(this.contentDV)
    }

    destroy() {
        super.destroy()
        CursorManager.removeDiv(this.contentDV)
    }

    showImage() {
        let image = this.getCurrentImagePath()
        this.mainPanoSphere.showImage(image)
    }

    showColor() {
        this.mainPanoSphere.setColor()
    }

    setPlugin(plugin) {
        if (plugin.toString() === '[TilePlugin Object]') {
            return console.warn('Tile plugin is already integrated with PanoGL V2.')
        }
        super.setPlugin(plugin)
    }

    _setupPanoGLObjects() {
        super._setupPanoGLObjects();

        this.allowDepthMap = false
        if (!this.allowDepthMap) return

        // let depthPath = 'http://localhost:9000/depth/IZMIR/Recording_28-04-2019_09-53-05/7.png'
        let depthPath = 'http://localhost:9000/depth/MERSIN2/ladybug_19027049_20190628_145806-000000/871.png'
        let vertextSTR = `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `

        let fragmentSTR = `
        #include <packing>
		varying vec2 vUv;
		uniform vec3 mouseScreenPosition;
		uniform mat4 modelViewMatrix;
		uniform mat4 projectionMatrix;
		uniform float cameraNear;
		uniform float cameraFar;
        uniform sampler2D t_depth;
        uniform float rangeAlpha;
        const float PI = 3.1415926535897932384626433832795;
        const float SCALE = 20.0; // recep
        //const float SCALE = 1111.0;
		bool isDepthValid(vec4 depth_px) {

            if(depth_px.a < 0.2) return false;
            
            return !(depth_px.r == 0.0 && depth_px.b == 0.0 && depth_px.g == 0.0);
		}

        bool isPositionValid(vec3 position) {
			return !(position.r == 0.0 && position.b == 0.0 && position.g == 0.0);
		}

		// float packColor(vec3 color) {
        //     float red = color.r;
        //     float green = color.g;
        //     float blue = color.b;
		// 	return blue + green * 256.0 + red * 256.0 * 256.0;
        // }
        
        // recep
        float packColor(vec3 color) {
            float red = color.r;
            float green = color.b;
            float blue = color.g;
			return blue + green * 256.0 + red * 256.0 * 256.0;
		}
        
        vec3 getDepthCoord(vec3 color, vec2 uv) {
            float dist_from_cam = packColor(color * 255.0) / SCALE;
            float pitch_in_rad = -(uv.y - 0.5) * PI;
            float yaw_in_rad = uv.x * (PI * 2.0);
            float neighbour = cos(pitch_in_rad) * dist_from_cam;
            float y = sin(pitch_in_rad) * dist_from_cam;
            float x = cos(yaw_in_rad) * neighbour;
            float z = sin(yaw_in_rad) * neighbour;
            return vec3(x,y,z);
		}

		void main() {
            
            gl_FragColor = vec4(1.0, 0.0, 0.0, 0.2);
            float dist = distance(gl_FragCoord.xy, mouseScreenPosition.xy);
            
            if(!isPositionValid(mouseScreenPosition)) {
                discard;
            }

			vec4 depthTexture = texture2D(t_depth, vUv);
            if(isDepthValid(depthTexture)) {
                
                vec3 depth_coord = getDepthCoord(depthTexture.rgb, vUv);
                float depth_distance = packColor(depthTexture.rgb * 255.0) / SCALE;
                float dist = distance(depth_coord, mouseScreenPosition);
				float CIRCLE_RADIUS = 30.0;
				float scale = 1.0 - (depth_distance / 10000.0);
				if(dist < CIRCLE_RADIUS) {
                    vec4 circleColor = vec4( 1.0, 1.0, 0.0, 1.0 );
                    vec4 circleColor2 = vec4( 1.0, 1.0, 1.0, 0.1 );
                    float lineSize = CIRCLE_RADIUS / 3.0;
                    float thLine = mod(dist,lineSize);
                    gl_FragColor.rgba = mix(circleColor2, circleColor, step(lineSize / 2.0,thLine));
				} else {
                    discard;
                    gl_FragColor.rgb = depthTexture.rgb;
                    gl_FragColor.a = rangeAlpha;
				}

			} else {
				discard;
            }
		}
        `

        let textureManager = new TextureLoader()
        textureManager.setCrossOrigin("anonymous")
        let depthTexture = textureManager.load(depthPath)
        depthTexture.magFilter = THREE.NearestFilter;
        depthTexture.minFilter = THREE.LinearFilter;
        let shaderMaterial = new ShaderMaterial({
            transparent: true,
            fragmentShader: fragmentSTR,
            vertexShader: vertextSTR,
            uniforms: {
                mouseScreenPosition: { value: new Vector3(200, 200) },
                t_depth: { value: depthTexture },
                cameraNear: { value: this.mainCam.near },
                cameraFar: { value: this.mainCam.far },
                rangeAlpha: { value: 1 },
            }
        });


        this._cursorScene = new Scene()

        let sphereGeometry = this.mainPanoSphere.panoSphere.geometry.clone()
        this._sphere = new Mesh(sphereGeometry, shaderMaterial);
        this._sphere.scale.set(-1, 1, 1)

        this._cursorScene.add(this._sphere);

        this.addEvent(PanoGLV2.RENDER_AFTER_GROUND, this, function () {
            this.renderer.clearDepth()
            this.renderer.render(this._cursorScene, this.mainCam)
        })


        let canvas
        let ctx
        let createCanvas = (canvasImage) => {
            canvas = document.createElement('canvas')
            canvas.width = canvasImage.width
            canvas.height = canvasImage.height
            canvas.style.width = canvas.width + "px"
            canvas.style.height = canvas.height + "px"
            ctx = canvas.getContext('2d')
            ctx.drawImage(canvasImage, 0, 0)
        }

        this.addEvent(PanoGLV2.MOUSE_MOVE2D, this, function (e) {

            if (ctx) {

                let direction = e.ray.direction.clone()
                let yaw = -Math.atan2(direction.x, direction.z)
                let yawInDeg = yaw * 180 / Math.PI
                yawInDeg %= 360

                let pitch = Math.asin(-direction.y)
                let pitchDegree = pitch * 180 / Math.PI
                let indexX = Math.floor(canvas.width * (yawInDeg / 360))
                let indexY = Math.floor(canvas.height * ((pitchDegree + 90) / 179))

                if (indexX < 0) {
                    indexX += canvas.width
                }

                var data = ctx.getImageData(indexX, indexY, 1, 1).data
                let r = data[0]
                let g = data[1]
                let b = data[2]
                let scale = 20// recep
                // let scale = 1111
                let distance = RGBToHex(r, b, g) / scale; // recep
                // let distance = RGBToHex(r, g, b) / scale;


                let neighbour = Math.cos(pitch) * distance
                let cartesianY = Math.sin(pitch) * distance
                let cartesianX = Math.cos(yawInDeg / 180 * Math.PI) * neighbour
                let cartesianZ = Math.sin(yawInDeg / 180 * Math.PI) * neighbour

                shaderMaterial.uniforms.mouseScreenPosition.value.x = cartesianX
                shaderMaterial.uniforms.mouseScreenPosition.value.y = cartesianY
                shaderMaterial.uniforms.mouseScreenPosition.value.z = cartesianZ;
            }
        })


        let onDataComplete = (e) => {


            let currentData = this.getCurrentPoint()
            // console.log(currentData);
            let heading = currentData.heading
            this._sphere.rotation.set(0, -90 / 180 * Math.PI, 0, 'YXZ')
            let { parent, img, dirname } = currentData
            img = img.replace('.jpeg', '.png')
            let canvasImage = new Image()
            canvasImage.crossOrigin = 'Anonymous'
            canvasImage.onload = () => {
                "Log yüklendi"
                createCanvas(canvasImage)
            }

            canvasImage.onerror = function () {
                console.log("Image could not be found : ", img)
            }


            if (typeof this.getCloudInstance === 'function') {
                let cloud = this.getCloudInstance()
                const queryPath = cloud.getURL()
                canvasImage.src = `${queryPath}depth/${parent}/${dirname}/${img}`
            }
        }
        this.addEvent(PanoGLV2.LOCATION_CHANGE, this, onDataComplete)

    }
    // onArrowClicked (e) {
    //     this.gotoLocation(e.data.lat, e.data.lon)
    // }

    gotoLocation(lat, lon) {
        super.gotoLocation(lat, lon)
    }

    onDataComplete(e) {
        super.onDataComplete(e)
    }

    gotoNextFrame() {
        let cp = this.dataParser.currentPoint
        let dirname = cp.dirname
        let otherPoints = this.dataParser.otherPoints
        let currentFrame = cp.frame
        let tempFrame = currentFrame + 1
        let point
        for (let i = 0; i < otherPoints.length; i++) {
            if (otherPoints[i].dirname === dirname && tempFrame === otherPoints[i].frame) {
                point = otherPoints[i]
            }
        }

        if (point) {
            this.gotoLocation(point.lat, point.lon)
            return true
        }
        return false
    }

    gotoPrevFrame() {
        let cp = this.dataParser.currentPoint
        let dirname = cp.dirname
        let otherPoints = this.dataParser.otherPoints
        let currentFrame = cp.frame
        let tempFrame = currentFrame - 1
        let point
        for (let i = 0; i < otherPoints.length; i++) {
            if (otherPoints[i].dirname === dirname && tempFrame === otherPoints[i].frame) {
                point = otherPoints[i]
            }
        }

        if (point) {
            this.gotoLocation(point.lat, point.lon)
            return true
        }
        return false
    }

    addResizeMaterial(material) {
        var cdv = this.contentDV
        material.resolution.set(cdv.clientWidth, cdv.clientHeight)
        this._resizeMaterials.push(material)
    }

    removeResizeMaterial(material) {
        let index = this._resizeMaterials.indexOf(material)
        if (index > -1) {
            this._resizeMaterials.splice(index, 1)
        }
    }

    resizeAll() {
        var cdv = this.contentDV
        this._resizeMaterials.forEach(material => {
            material.resolution.set(cdv.clientWidth, cdv.clientHeight)
        })

        super.resizeAll()
    }

    /**
    * Mevcut panaroma'nın başlatılmamış ve bağlantılı bir clone'unu çıkartır.
    * @method createConnectedPano
    * @memberof PanoGL
    * @param {String} divname
    * @return {PanoGL}
    * @public
    * @instance
    */
    createConnectedPano(divname) {
        if (!this.dataParser || !this.dataParser.currentPoint) return

        var pano = new PanoGLV2(Object.assign({}, this._options, {
            content: divname
        }))
        pano.allowSecondImage = this.allowSecondImage
        pano.thumbSize = this.thumbSize
        pano.start()
        if (pano.controller) {
            pano.controller.setRotationY(this.mainCam.rotation.y)
        } else {
            pano.addEvent(PanoGL.SETUP_COMLETE, this, this._connectedPanoSetup)
        }

        var i
        var plugins = this.plugins
        for (i = 0; i < plugins.length; i++) {
            let plugin = plugins[i]
            if (plugin.cloneable) {
                var ClassIns = plugin.constructor // .ClassInstance;

                var ops = plugin.initialOptions
                var p = new ClassIns(ops)
                plugin.notifyNewInstance()
                pano.setPlugin(p)
            }
        }

        var c = PanoGL._instances.length
        for (i = 0; i < c; i++) {
            var a = PanoGL._instances[i]
            for (var j = 1; j < c; j++) {
                var b = PanoGL._instances[j]
                if (a !== b) {
                    b.assignPano(a)
                    a.assignPano(b)
                }
            }
        }

        return pano
    }

    getAzimuth() {
        let currentPoint = this.getCurrentPoint()
        if (currentPoint !== null) {
            return 360 - this.controller.getRotationYDeg()
        }
        return 0
    }

    locationToCartesian(lon, lat, alt) {
        if (!this.dataParser || !this.dataParser.currentPoint) return null

        var _currentData = this.dataParser.currentPoint

        if (!_currentData) return null

        var dist = Utils.heversine(_currentData.lat, lat, _currentData.lon, lon)
        var azimuth = ((Utils.bearing(_currentData.lat, _currentData.lon, lat, lon))) % 360
        azimuth = (360 - azimuth) % 360

        var theta = azimuth
        var gm = this.groundMaster
        var hip = (dist * Math.abs(gm.cameraHeightInPixel)) / gm.cameraHeight
        var rad = (360 - (theta + 90)) * TO_RAD
        var xx = hip * Math.cos(rad)
        var zz = hip * Math.sin(rad)
        var yy = -gm.cameraHeightInPixel

        if (alt !== undefined && alt !== null) {
            alt = alt - _currentData.altitude
            alt = alt / gm.cameraHeight
            yy = alt * gm.cameraHeightInPixel
        }

        return { x: xx, y: yy, z: zz }
    }

    /*
    onStateChange({state, prevState}){
        if(prevState === PANO_STATES.VIDEO_PLAY) {
            clearTimeout(this._playTimeout)
            this.setArrowVisibility(true)
            this.setGroundLabelVisibility(true)
            this.disableGroundNav(false)
            // this.thumbSize = {x:512, y:256}
            this.removeEvent(PanoGL.DATA_COMPLETE,this._repeatDataComplete)
            // this.removeEvent(PanoGL.THUMB_IMAGE_DISPLAYED,this._onThumbLoaded)
            this.allowSecondImage = true
        }

        if(state === PANO_STATES.VIDEO_PLAY) {
            this.allowSecondImage = false
            this.setArrowVisibility(false)
            this.setGroundLabelVisibility(false)
            this.disableGroundNav(true)
            pano.mainPanoSphere.setColor()
            this.addEvent(PanoGL.DATA_COMPLETE,this,this._repeatDataComplete)
            let current = this.getCurrentPoint()
            let otherdata = this.getOtherData()
            let dirname = current.dirname
            let sameDir = otherdata.filter(i => i.dirname === dirname)
            let found = this.getNextFrame(sameDir, current)
            clearTimeout(this._playTimeout)
            if(found) {
                this._playTimeout = setTimeout(() => {
                    this.gotoLocation(found.lat, found.lon)
                }, this._playFPS)
            } else {
                this.setState(PANO_STATES.DEFAULT)
            }
            // this.thumbSize = {x:2048, y:1024}
            // this.addEvent(PanoGL.THUMB_IMAGE_DISPLAYED,this,this._onThumbLoaded)
        }
    }

    onDataComplete(e) {
        if(this._state === 'DEFAULT') {
            super.onDataComplete(e)
        } else {
            this._repeatDataComplete(e)
        }
    }

    playReverse(b) {
        this._playReverse = b
    }

    isPlayingReverse() {
        return this._playReverse
    }

    setPlayFPS(v) {
        this._playFPS = 1000 / v
    }

    getPlayFPS() {
        return 1000 / this._playFPS
    }

    _onThumbLoaded(e) {
        let prevPoint = this.dataParser.prevPoint
        let prevHeading = 0
        if(prevPoint) { prevHeading = prevPoint.heading }
        let controller = this.getController()
        let lastDegree = controller.getRotationYDeg() + prevHeading
        let currentHeading = this.getCurrentPoint().heading
        let degreeToLookAt = 0 - (currentHeading - lastDegree)
        controller.setRotationY(degreeToLookAt / 180 * Math.PI)
    }

    getNextFrame(sameDir, current) {
        sameDir = sameDir.sort((a, b) => a.frame - b.frame)
        if(!this._playReverse) {
            sameDir = sameDir.reverse()
        }

        let found
        for (let i = 0; i < sameDir.length; i++) {
            let dir = sameDir[i]

            if(this._playReverse) {
                if(dir.frame < current.frame) {
                    found = dir
                }
            } else {
                if(dir.frame > current.frame) {
                    found = dir
                }
            }

        }
        return found
    }

    _repeatDataComplete(e) {
        let current = this.getCurrentPoint()
        let otherdata = this.getOtherData()
        let dirname = current.dirname
        let sameDir = otherdata.filter(i => i.dirname === dirname)
        let found = this.getNextFrame(sameDir, current)

        // this.getTilePlugin().thumbDisplayedResetIt()
        let tiles = this.getTilePlugin().getVisibleTiles()

        clearTimeout(this._playTimeout)

        tiles = tiles.filter(t => {
            if(t.cordy > 2 && t.cordy < 6) {
                return true
            }
        })

        let tileSize = tiles.length
        let index = 0
        const onLoadTiles = (e) => {
            index++
            let tile = e.target
            let eventer = tile.eventer
            eventer.removeEvent('onImageLoadEnd', onLoadTiles)

            if(index === tileSize) {
                if(found) {

                    let allTimes = this.getTilePlugin().getTiles()
                    allTimes.forEach(t => {
                        if(tiles.indexOf(t) === -1) {
                            t.reset()
                        } else {
                            t.show()
                        }
                    })

                    this._playTimeout = setTimeout(() => {
                        this.gotoLocation(found.lat, found.lon)
                    }, this._playFPS)

                } else {
                    this.setState(PANO_STATES.DEFAULT)
                }
            }
        }

        for (let i = 0; i < tileSize; i++) {
            const tile = tiles[i];
            tile.eventer.addEvent('onImageLoadEnd',onLoadTiles)
            tile.loadOnlyImageOnFirstZoom()
        }
    }

    playOnCurrentRoad (relativeToCar) {
        let state = this._state
        this._relativeToCar = relativeToCar
        if(state !== 'VIDEO_PLAY') {
            this.setState(PANO_STATES.VIDEO_PLAY)
        }
    }

    stopPlaying() {
        this.setState(PANO_STATES.DEFAULT)
    }

    setState (state) {
        let isARealState = Object.values(PANO_STATES).indexOf(state) > -1
        if(isARealState) {
            if(this._state !== state) {
                let prevState = this._state
                this._state = state
                this.throwEvent({type: PanoGL.STATE_CHANGE, state: state, prevState})
            }
        } else {
            console.warn(`${state} is an unknown state`)
        }
    }
    */
}

PanoGLV2.TilePlugin = TilePlugin

export { PanoGLV2 }
