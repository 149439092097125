
function CursorManager() {
    throw new Error('Static class!')
}

let _doms = []
CursorManager.addDiv = function (dom) {
    if (_doms.indexOf(dom) === -1) {
        _doms.push(dom)
    }
}

CursorManager.removeDiv = function (dom) {
    let index = _doms.indexOf(dom)
    if (index > -1) {
        _doms.splice(index, 1)
    }
}

console.log("----> Cursor Kapatıldı");

var recentCursorImportance = 0
CursorManager.setCursor = function (curserModel, importance, reset) {

    return


    importance = importance === undefined ? 0 : importance

    if (recentCursorImportance <= importance) {

        for (let i = 0; i < _doms.length; i++) {
            const dom = _doms[i];
            // var style = document.body.style
            const style = dom.style
            const cursor = style.cursor
            if (cursor !== curserModel) {
                style.cursor = curserModel
                recentCursorImportance = importance
            }
        }
    }

    if (reset === true) {
        recentCursorImportance = 0
    }
}

export { CursorManager }
