import { Base } from './Base'
import { AssetManager } from './AssetManager'
import { PanoGL } from './PanoGL'

Arrow.prototype = Object.create(THREE.Mesh.prototype)
Arrow.CLICK = 'click'
var arrowbaseprot = Object.create(Base.prototype)
for (var str in arrowbaseprot) { Arrow.prototype[str] = arrowbaseprot[str] }

/**
 * Panaroma açmak için instance oluşturulması gerekiyor. Bütün yönetim instance object üzerinden işliyor.
 * @class Arrow
 * @extends THREE.Mesh
*/
Arrow.prototype.constructor = Arrow

/**
 * Okun merkeze olan uzaklığı. Oklar oluşturulmadan önce değiştilmelidir.
 * @memberof Arrow
 * @property {float} DISTANCE
 * @static
 * @public
*/
Arrow.DISTANCE = 75
Arrow.counter = 0

/**
 * Panaroma açmak için instance oluşturulması gerekiyor. Bütün yönetim instance object üzerinden işliyor.
 * @memberof Arrow
 * @param {PanoGL} panogl
 * @param {THREE.Scene} renderScene
 * @param {THREE.PerspectiveCamera} renderCam
*/
function Arrow (panogl, renderScene, renderCam) {
    this.panogl = panogl
    Arrow.counter++
    this.nameID = 'Arrow ' + Arrow.counter
    var t = this
    if (!Arrow.material) {
        var assetManager = AssetManager.getInstance()
        var mp = assetManager.GetAssetNoCache(AssetManager.getPathAt(7), function () {
            PanoGL.waitingForGlobalRender = true
        })
        mp.generateMipmaps = false
        mp.magFilter = THREE.LinearFilter
        mp.minFilter = THREE.LinearFilter
        Arrow.material = new THREE.MeshBasicMaterial({ depthTest: false, depthWrite: false, transparent: true, map: mp, name: 'arrowMaterial' })
    }

    var geom = this.getGeometry()
    THREE.Mesh.apply(this, [geom, Arrow.material])
    this.scale.set(0.15, 0.15, 0.15)
    this.setClickable(true, panogl, renderScene, renderCam)
    t.panogl.isDirty = true
}

/**
 * Data setter
 * @method setData
 * @memberof Arrow
 * @param {Object} obj
 * @public
 * @instance
*/
Arrow.prototype.setData = function (obj) {
    this.data = obj
}

/**
 * İlk çağırışda geometry bir kere oluşturulur ve o geometri sürekli geri döner.
 * @method getGeometry
 * @memberof Arrow
 * @return {THREE.Geometry}
 * @public
 * @instance
*/
Arrow.prototype.getGeometry = function () {
    if (!Arrow._geom) {
        var half = 42
        var halfH = 30
        var dist = Arrow.DISTANCE
        var ara = 3
        var TV = THREE.Vector3
        var TV2 = THREE.Vector2
        var TF3 = THREE.Face3
        var geom = new THREE.Geometry()

        var v1 = new TV(-half, 0, -halfH - dist)
        var v2 = new TV(half, 0, -halfH - dist)
        var v3 = new TV(half, 0, halfH - dist)
        var v4 = new TV(-half, 0, halfH - dist)

        var v5 = new TV(-half, -ara, -halfH - dist)
        var v6 = new TV(half, -ara, -halfH - dist)
        var v7 = new TV(half, -ara, halfH - dist)
        var v8 = new TV(-half, -ara, halfH - dist)
        var vertices = [v5, v6, v7, v8, v1, v2, v3, v4]

        var faces = [
            new TF3(1, 0, 2),
            new TF3(0, 3, 2),
            new TF3(5, 4, 6),
            new TF3(4, 7, 6),
            new TF3(6, 4, 5),
            new TF3(6, 7, 4),
            new TF3(2, 0, 1),
            new TF3(2, 3, 0)
        ]

        var uvs = [
            new TV2(1, 1), // 1
            new TV2(0, 1), // 0
            new TV2(1, 0.6328125) // 2
        ]
        var uvs2 = [
            new TV2(0, 1), // 0
            new TV2(0, 0.6328125), // 3
            new TV2(1, 0.6328125) // 2
        ]
        //
        var uvs3 = [
            new TV2(1, 0.5), // 1
            new TV2(0, 0.5), // 0
            new TV2(1, 0.140625) // 2
        ]

        var uvs4 = [
            new TV2(0, 0.5), // 0
            new TV2(0, 0.140625), // 3
            new TV2(1, 0.140625) // 2
        ]

        var uvs5 = [
            new TV2(1, 0.140625), // 2
            new TV2(0, 0.5), // 0
            new TV2(1, 0.5) // 1
        ]

        var uvs6 = [
            new TV2(1, 0.140625), // 2

            new TV2(0, 0.140625), // 3
            new TV2(0, 0.5) // 0
        ]

        var uvs7 = [
            new TV2(1, 0.6328125), // 2
            new TV2(0, 1), // 0
            new TV2(1, 1) // 1
        ]

        var uvs8 = [
            new TV2(1, 0.6328125), // 2
            new TV2(0, 0.6328125), // 3
            new TV2(0, 1) // 0
        ]

        geom.vertices = vertices
        geom.faces = faces
        geom.computeFaceNormals()
        geom.faceVertexUvs[0].push(uvs3, uvs4, uvs, uvs2, uvs5, uvs6, uvs7, uvs8)
        geom.mergeVertices()
        Arrow._geom = geom
    }

    return Arrow._geom
}

/**
 * Yön setterı
 * @method setAngle
 * @memberof Arrow
 * @param {float} val Derece
 * @public
 * @instance
*/
Arrow.prototype.setAngle = function (val) {
    this.rotation.y = val
}

export { Arrow }
