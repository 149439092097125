
let AnkaPromise
if (typeof window.Promise === 'function') {
    AnkaPromise = window.Promise
} else {
    AnkaPromise = function (callback) {
        if (!(typeof callback === 'function')) {
            throw new TypeError('Promise resolver undefined is not a function')
        }

        const resolve = (response) => {
            this.state = 'RESOLVED'
            let thenCallabacks = this._thencb
            if (thenCallabacks.length > 0) {
                let a = this._thencb.shift()(response)
                setTimeout(resolve, 0, a)
            }
        }

        const reject = (reason) => {
            this.state = 'REJECTED'
            this._catchcb(reason)
        }

        setTimeout(() => {
            try {
                callback(resolve, reject)
            } catch (error) {
                this.state = 'REJECTED'
                this._thencb.shift()
                this._catchcb(error)
                let thenCallabacks = this._thencb
                if (thenCallabacks.length > 0) {
                    let a = this._thencb.shift()(undefined)
                    setTimeout(resolve, 0, a)
                }
            }
        }, 0)
    }

    Object.assign(AnkaPromise.prototype, {
        state: 'PENDING',
        _catchcb: undefined,
        _thencb: [],
        then: function (callback) {
            this._thencb.push(callback)
            return this
        },
        catch: function (callback) {
            this._catchcb = callback
            return this
        }
    })
}

export { AnkaPromise }
