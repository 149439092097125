import {Utils} from './Utils'

Label.prototype = Object.create(THREE.Object3D.prototype)
Label.prototype.constructor = Label
Label.counter = 0
Label.DISTANCE = 70
function Label (panogl) {
    Label.counter++
    this.panogl = panogl
    THREE.Object3D.apply(this, arguments)
    this.scale.set(0.3, 0.3, 0.3)

    var style = this.panogl.getLabelStyle()
    var zOfset = style && style.zOffset !== undefined ? -style.zOffset : -Label.DISTANCE
    var yOfset = style && style.yOffset !== undefined ? style.yOffset : 20
    // var borderR = Math.round(255 * Math.random());
    // var borderG = Math.round(255 * Math.random());
    var spritey = Utils.makeTextSprite(null, 'Arrow Data Yapısı' + Label.counter)
    spritey.position.set(0, yOfset, zOfset)
    this.sprite = spritey
    this.add(spritey)
}

Label.prototype.setAngle = function (val) {
    this.rotation.y = val
}

Label.prototype.setText = function (text) {
    var style = this.panogl.getLabelStyle()

    if (style) {
        var fontSize = style.fontSize
        var backgroundColor = style.backgroundColor
        var borderColor = style.borderColor
        var textColor = style.textColor
        var borderThickness = style.borderThickness
        var cornerRound = style.cornerRound
        Utils.makeTextSprite(this.sprite, text, fontSize, backgroundColor, borderColor, textColor, borderThickness, cornerRound)
    } else {
        Utils.makeTextSprite(this.sprite, text)
    }
}

export {Label}
