
import { Base } from './Base'
import { AssetManager } from './AssetManager'
import { PanoGL } from './PanoGL'
import { Utils, TO_DEG, TO_RAD } from './Utils'

GroundMaster.prototype = Object.create(Base.prototype)
GroundMaster.prototype.constructor = GroundMaster

GroundMaster.GROUND_CLICK = 'onGroundClick'
GroundMaster.GROUND_MOVE = 'onCursorMoveOnGround'
GroundMaster.GROUND_MOUSE_LEFT = 'onCursorLeftGround'
GroundMaster.CAMERA_ORIENTATION_CHANGE = 'onCameraOrientationChange'
GroundMaster.CAMERA_HEIGHT_CHANGE = 'onCameraHeightChange'

function GroundMaster(panoGl, canvasElement, panoSphere, camera, renderer) {
    this.__wallGeometries = []

    this._showNoSymbol = false
    this.isMouseOnGround = false
    this._pNormal = new THREE.Vector3(0, 1, 0)
    this._pCenter = new THREE.Vector3(0, 100, 0)
    this._op = new THREE.Vector3(0, 0, 0)
    this._rc = new THREE.Raycaster()
    this._tv = new THREE.Vector3()
    this._mouseX = 0
    this._mouseY = 0
    this.camera = camera
    this.renderer = renderer
    this.panoSphere = panoSphere
    this.panoGl = panoGl
    this.isDirty = true
    this.points = []
    this._moveEvent = { type: GroundMaster.GROUND_MOVE }
    this._clickEvent = { type: GroundMaster.GROUND_CLICK }
    this.cameraHeight = 0
    this.cameraHeightInPixel = 0
    this._isNavAllowed = true
    this._camMetPixelOriantation = 100
    Base.apply(this, [])
    this.canvasElement = canvasElement
    this.mouseMoveFNC = this.onMouseMove.bind(this)
    this.mouseClikFNC = this.onMouseClick.bind(this)
    this.mouseOutFNC = this.onMouseOut.bind(this)
    var scope = this
    this.panoGl.getRendererDom().addEventListener('mouseleave', function (e) {
        if (scope.isMouseOnGround) {
            scope.isMouseOnGround = false
            scope.throwEvent({ type: GroundMaster.GROUND_MOUSE_LEFT })
        }
    })
    var geom = new THREE.PlaneGeometry(50, 50, 1, 1)
    var assetManager = AssetManager.getInstance()

    var managerMarkerTexture = assetManager.GetAssetNoCache(AssetManager.getPathAt(1))
    managerMarkerTexture.generateMipmaps = false
    managerMarkerTexture.minFilter = THREE.LinearFilter

    var material = new THREE.MeshBasicMaterial({ transparent: true, name: 'groundBasicMat', map: managerMarkerTexture, depthTest: false })
    this._arrowPlane = new THREE.Mesh(geom, material)

    var closeTexture = assetManager.GetAssetNoCache(AssetManager.getPathAt(2))
    closeTexture.generateMipmaps = false
    closeTexture.minFilter = THREE.LinearFilter
    var close = new THREE.MeshBasicMaterial({ transparent: true, name: 'groundCloseMat', map: closeTexture, depthTest: false })
    this._crossPlane = new THREE.Mesh(geom, close)

    this._tm = new THREE.Object3D()
    this._tm.add(this._arrowPlane)
    this._tm.add(this._crossPlane)

    this._tm.rotation.x = -Math.PI / 2
    this.panoGl.markerContailer.add(this._tm)

    var drawingCrossText = assetManager.GetAssetNoCache(AssetManager.getPathAt(3))
    drawingCrossText.generateMipmaps = false
    drawingCrossText.minFilter = THREE.LinearFilter

    var materialDC = new THREE.MeshBasicMaterial({ transparent: true, name: 'materialDCMat', map: drawingCrossText, depthTest: false })
    this._crossGM = new THREE.Mesh(geom, materialDC)
    this.panoGl.markerContailer.add(this._crossGM)
    this._crossGM.visible = false
    this._crossGM.rotation.x = this._tm.rotation.x
    this._crossGM.scale.set(2, 2, 2)
    var hinterTexture = assetManager.GetAssetNoCache(AssetManager.getPathAt(4))
    hinterTexture.generateMipmaps = false
    hinterTexture.minFilter = THREE.LinearFilter
    var met = new THREE.MeshBasicMaterial({ opacity: 0.3, transparent: true, depthTest: false, map: hinterTexture })
    var pg = new THREE.PlaneGeometry(50, 50, 1, 1)
    this._hinter = new THREE.Mesh(pg, met)
    this._hinter.rotation.x = -Math.PI / 2
    this.panoGl.markerContailer.add(this._hinter)

    this.wallBoxMesh = new THREE.Mesh(new THREE.BoxBufferGeometry(50, 70, 1, 1, 1, 1), new THREE.MeshBasicMaterial({ color: 0xffffff, name: 'wallMat', transparent: true, opacity: 0.4 }))
    this.panoGl.markerContailer.add(this.wallBoxMesh)

    canvasElement.addEventListener('mouseout', this.mouseOutFNC)
    canvasElement.addEventListener('mousedown', this.onMouseDown.bind(this))
    document.addEventListener('mouseup', this.onMouseUp.bind(this))
    canvasElement.addEventListener('mousemove', this.mouseMoveFNC)
    canvasElement.addEventListener('click', this.mouseClikFNC)

    this.hintChangeEvent = { type: 'onHintChangeEvent' }
}

GroundMaster.prototype.disableWallIntersection = function () {
    this.__isWallDisabled = true
}
GroundMaster.prototype.enableWallIntersection = function () {
    this.__isWallDisabled = false
}

GroundMaster.prototype.setCameraHeight = function (heightInMeter, forceRecalculate) {
    if (heightInMeter !== this.cameraHeight || forceRecalculate) {
        this.cameraHeight = heightInMeter
        this.cameraHeightInPixel = (this.cameraHeight * this._camMetPixelOriantation) / 2
        this.setCursorYPosition(this.cameraHeightInPixel)

        this.throwEvent({ type: GroundMaster.CAMERA_HEIGHT_CHANGE, meter: this.getCameraHeight(), pixel: this.getGroundPos() })
    }
}

GroundMaster.prototype.getCameraHeighOrientationMultiplier = function () {
    return this._camMetPixelOriantation
}

GroundMaster.prototype.setCameraHeighOrientationMultiplier = function (v) {
    if (this._camMetPixelOriantation !== v) {
        this._camMetPixelOriantation = v
        this.setCameraHeight(this.cameraHeight, true)
        this.throwEvent({ type: GroundMaster.CAMERA_ORIENTATION_CHANGE, orientation: v })
    }
}

GroundMaster.prototype.getCameraHeight = function () {
    return this.cameraHeight
}

GroundMaster.prototype.getGroundPos = function () {
    return this.cameraHeightInPixel
}

GroundMaster.prototype.onMouseDown = function (e) {
    this.allowClick = true
    this.isMouseDown = true
    this._startDownX = e.offsetX
    this._startDownY = e.offsetY
}

GroundMaster.prototype.onMouseUp = function (e) {
    this.isMouseDown = false
}

GroundMaster.prototype.onMouseMove = function (e) {
    this.isMouseMoving = true
    this._mouseX = e.offsetX
    this._mouseY = e.offsetY
    this.isDirty = true
    this._mouseLeft = false

    if (this.isMouseDown && this.allowClick) {
        var dmx = Math.abs(this._mouseX - this._startDownX)
        if (dmx > 2) {
            this.allowClick = false
            this._tm.visible = false
            return
        }

        var dmy = Math.abs(this._mouseY - this._startDownY)

        if (dmy > 2) {
            this._tm.visible = false
            this.allowClick = false
        }
    }
}

GroundMaster.prototype.onMouseOut = function (e) {
    this._mouseLeft = true
    this._tm.visible = false
    this.isDirty = true
}

GroundMaster.prototype.updateMouseCoordinates = function () {
    this.isMouseMoving = true
    this.update()
}

GroundMaster.prototype.update = function () {
    if (this._isNavAllowed) {
        this.navAllowedUpdate()
    } else {
        this.navDisabledUpdate()
    }

    this.isDirty = false
}

GroundMaster.prototype.navDisabledUpdate = function () {
    if (this.isMouseMoving) {
        this._tm.visible = false
        this.isMouseMoving = false

        if (!this._currentData) return

        this._tv.x = (this._mouseX / this.canvasElement.width) * 2 - 1
        this._tv.y = -(this._mouseY / this.canvasElement.height) * 2 + 1
        this._rc.setFromCamera(this._tv, this.camera)

        var hitsWall = this.checkWallIntersection(this._rc, false)

        if (hitsWall) {
            return
        }

        var dist = -(this._pNormal.dot(this.camera.position) - this._pNormal.dot(this._pCenter)) / this._pNormal.dot(this._rc.ray.direction)

        if (dist > 0) {
            this._destPoint = null
            if (this.isMouseOnGround) {
                this.isMouseOnGround = false
                this.throwEvent({ type: GroundMaster.GROUND_MOUSE_LEFT })
            }
            return
        }

        if (!this.isMouseOnGround) {
            this.isMouseOnGround = true
        }

        this._op.x = this.panoSphere.camera.position.x - this._rc.ray.direction.x * dist
        this._op.y = this.panoSphere.camera.position.y - this._rc.ray.direction.y * dist
        this._op.z = this.panoSphere.camera.position.z - this._rc.ray.direction.z * dist
        this._crossGM.position.set(this._op.x, this._op.y, this._op.z)

        // if no symbol wanted during draw
        this._crossGM.visible = !this._showNoSymbol

        this._crossGM.rotation.set(-Math.PI / 2, 0, 0, 'YXZ')
        this._destPoint = this.coordinate(this._op, false)
        this.colorDrawCursorAccordingtoDistance(false)
    }
}


GroundMaster.prototype.canvasToCartesian = function (mouseX, mouseY, cartesianY = 100) {

    const panogl = this.panoGl
    const camera = panogl.getMainCamera()
    const canvas = panogl.getRendererDom()
    const tempVect = new THREE.Vector3()
    const normalVect = new THREE.Vector3(0, 1, 0)
    const rayCaster = new THREE.Raycaster()
    const centerVect = new THREE.Vector3(0, -cartesianY, 0)

    tempVect.x = (mouseX / canvas.clientWidth) * 2 - 1
    tempVect.y = -(mouseY / canvas.clientHeight) * 2 + 1
    rayCaster.setFromCamera(tempVect, camera)

    var dist = -(normalVect.dot(camera.position) - normalVect.dot(centerVect)) / normalVect.dot(rayCaster.ray.direction)

    var posVector = new THREE.Vector3()
    posVector.x = camera.position.x - rayCaster.ray.direction.x * dist
    posVector.y = camera.position.y - rayCaster.ray.direction.y * dist
    posVector.z = camera.position.z - rayCaster.ray.direction.z * dist

    return posVector
}

GroundMaster.prototype.navAllowedUpdate = function () {
    this._crossGM.visible = false
    if (PanoGL.isPointerOnClickableObject) {
        this._tm.visible = false
        this.isDirty = false
        this._hinter.visible = false

        if (this.currentHint) {
            this.currentHint = null
            this.hintChangeEvent.isValid = false
            this.hintChangeEvent.lon = null
            this.hintChangeEvent.lat = null
            this.throwEvent(this.hintChangeEvent)
        }

        return
    }

    if (!this._mouseLeft && this.allowClick) this._tm.visible = true

    if (this.isMouseMoving) {
        this.isMouseMoving = false
        if (!this._currentData) return

        this._tv.x = (this._mouseX / this.canvasElement.width) * 2 - 1
        this._tv.y = -(this._mouseY / this.canvasElement.height) * 2 + 1
        this._rc.setFromCamera(this._tv, this.camera)
        var hitsWall = this.checkWallIntersection(this._rc, true)

        if (hitsWall) {
            return
        }

        this.wallBoxMesh.visible = false

        var dist = -(this._pNormal.dot(this.camera.position) - this._pNormal.dot(this._pCenter)) / this._pNormal.dot(this._rc.ray.direction)

        if (dist > 0) {
            this._destPoint = null
            this.isDirty = false
            this._tm.visible = false

            if (this.isMouseOnGround) {
                this.isMouseOnGround = false
                this.throwEvent({ type: GroundMaster.GROUND_MOUSE_LEFT })
            }

            return
        }

        if (!this.isMouseOnGround) {
            this.isMouseOnGround = true
        }

        this._op.x = this.panoSphere.camera.position.x - this._rc.ray.direction.x * dist
        this._op.y = this.panoSphere.camera.position.y - this._rc.ray.direction.y * dist
        this._op.z = this.panoSphere.camera.position.z - this._rc.ray.direction.z * dist
        this._tm.position.set(this._op.x, this._op.y, this._op.z)
        this._destPoint = this.coordinate(this._op, false)

        this.destinationPoint()
    }
}

GroundMaster.prototype.checkWallIntersection = function (rayCaster, navEnabled) {
    if (!this.__isWallDisabled && this.__wallGeometries) {
        var intersects = rayCaster.intersectObjects(this.__wallGeometries)
        if (intersects.length > 0) {
            var inter = intersects[0]
            this._tm.visible = false

            var yPos = -Math.asin(inter.face.normal.x)

            var writePos = Math.round(yPos * TO_DEG)
            if (writePos < 0) writePos += 360

            var yaw = Math.atan2(inter.face.normal.x, inter.face.normal.z)

            if (navEnabled) {
                this.wallBoxMesh.visible = true
                this.wallBoxMesh.position.set(inter.point.x, inter.point.y, inter.point.z)
                this.wallBoxMesh.rotation.set(0, yaw, 0, 'YXZ')
                this._crossGM.visible = false
            } else {
                this._crossGM.visible = true
                this._crossGM.position.set(inter.point.x, inter.point.y, inter.point.z)
                this._crossGM.rotation.set(0, yaw, 0, 'YXZ')
                this._crossGM.visible = true
                this.wallBoxMesh.visible = false

                this.colorDrawCursorAccordingtoDistance(true)
            }
            this._destPoint = this.coordinate({ x: inter.point.x, y: inter.point.y, z: inter.point.z }, true, inter.face.normal)
            return true
        }
    }

    return false
}

GroundMaster.prototype.colorDrawCursorAccordingtoDistance = function (isOnWall) {
    if (isOnWall) {
        this._crossGM.material.color.r = 1
        this._crossGM.material.color.g = 0
        this._crossGM.material.color.b = 1
        return
    }

    var p = this._crossGM.position
    var farMeter = Math.sqrt((p.x * p.x) + (p.z * p.z))

    if (farMeter < 150) {
        this._crossGM.material.color.r = 1
        this._crossGM.material.color.g = 0
        this._crossGM.material.color.b = 0
    } else if (farMeter < 200) {
        this._crossGM.material.color.r = 1
        this._crossGM.material.color.g = 1
        this._crossGM.material.color.b = 0
    } else if (farMeter >= 200 && farMeter <= 500) {
        this._crossGM.material.color.r = 0
        this._crossGM.material.color.g = 0.7
        this._crossGM.material.color.b = 0
    } else if (farMeter > 500 && farMeter < 650) {
        this._crossGM.material.color.r = 1
        this._crossGM.material.color.g = 1
        this._crossGM.material.color.b = 0
    } else {
        this._crossGM.material.color.r = 1
        this._crossGM.material.color.g = 0
        this._crossGM.material.color.b = 0
    }
}

GroundMaster.prototype.disableNavigation = function () {
    var t = this
    t._isNavAllowed = false
    t._tm.visible = false
    t._hinter.visible = false
}

GroundMaster.prototype.showGroundSymbol = function (v) {
    this._showNoSymbol = !v
}

GroundMaster.prototype.enableNavigation = function () {
    this.allowClick = false
    this._isNavAllowed = true
}

GroundMaster.prototype.show3DCursorOnMap = function (x, y, z, isWall, normal) {
    this.isDirty = true
    this.isMouseMoving = false
    if (!isWall) {
        this._op.set(x, y, z)

        if (this._isNavAllowed) {
            this._tm.visible = true
            this._tm.position.set(x, y, z)
            this.destinationPoint()
        } else {
            this._crossGM.visible = true
            this._crossGM.position.set(x, y, z)
            this._crossGM.rotation.set(-Math.PI / 2, 0, 0, 'YXZ')
        }
        this.wallBoxMesh.visible = false
    } else {
        var yaw = Math.atan2(normal.x, normal.z)
        if (this._isNavAllowed) {
            this.wallBoxMesh.rotation.set(0, yaw, 0, 'YXZ')
            this.wallBoxMesh.visible = true
            this.wallBoxMesh.position.set(x, y, z)
            this._crossGM.visible = false
            this._tm.visible = false
        } else {
            this.wallBoxMesh.visible = false
            this._crossGM.position.set(x, y, z)
            this._crossGM.rotation.set(0, yaw, 0, 'YXZ')
            this._crossGM.visible = true
        }
    }

    this.colorDrawCursorAccordingtoDistance(isWall)
}

GroundMaster.prototype.coordinate = function (pos, isWall, normal) {
    var o = this.posToLoc(pos)
    this._moveEvent.lat = o.lat
    this._moveEvent.lon = o.lon
    this._moveEvent.alt = o.alt
    this._moveEvent.position = Object.assign({}, pos)
    this._moveEvent.isWall = isWall
    this._moveEvent.normal = normal
    this.throwEvent(this._moveEvent)
    return o
}

GroundMaster.prototype.posToLoc = function (pos) {
    this._theta = (Math.atan2(pos.z, pos.x) * 180 / Math.PI)
    this._theta += 90
    this._at = (Math.atan2(pos.z, pos.x) + (Math.PI / 2))
    this._hip = Math.sqrt((pos.x * pos.x) + (pos.z * pos.z))
    this._distToPX = (this.cameraHeight * this._hip) / Math.abs(this.cameraHeightInPixel)
    this.lastPos = pos
    let currentData = this._currentData
    var alt = currentData.altitude + (pos.y / this.cameraHeightInPixel) * this.cameraHeight
    var loc = Utils.destinationPoint(currentData.lat, currentData.lon, this._distToPX, this._theta)
    loc.alt = alt
    return loc
}

GroundMaster.prototype.setCursorYPosition = function (y) {
    this._pCenter.y = y
}

GroundMaster.prototype.setCurrentData = function (data) {
    this._currentData = data
}

GroundMaster.prototype.addStopPoints = function (data) {
    this.points.length = 0

    let currentData = this._currentData
    var altidute = currentData.altitude
    for (var i = 0; i < data.length; i++) {
        var o = data[i]
        this._theta = o.azimuth
        var dist = Utils.heversine(currentData.lat, o.lat, currentData.lon, o.lon)
        var hip = (dist * Math.abs(this.cameraHeightInPixel)) / this.cameraHeight
        var rad = (360 - (o.azimuth + 90)) * TO_RAD
        var xx = hip * Math.cos(rad)
        var zz = hip * Math.sin(rad)
        var yy = (altidute - o.altitude) * Math.abs(this.cameraHeightInPixel) / this.cameraHeight
        this.points.push({ x: xx, z: zz, y: yy, isc: false, lat: o.lat, lon: o.lon })
    }
    this.points.push({ x: 0, z: 0, isc: true })
}

GroundMaster.prototype.destinationPoint = function () {
    var selectedPoint
    var closest = 600000000
    var dx
    var dz

    for (var i = 0; i < this.points.length; i++) {
        dx = this.points[i].x - this._op.x
        dz = this.points[i].z - this._op.z
        var sqrt = Math.sqrt((dx * dx) + (dz * dz))
        if (sqrt < closest) {
            closest = sqrt
            selectedPoint = this.points[i]
            selectedPoint.dx = dx
            selectedPoint.dz = dz
        }
    }

    if (selectedPoint && closest < 500) {
        if (!selectedPoint.isc) {
            if (this.currentHint !== selectedPoint) {
                this._hinter.position.x = selectedPoint.x
                this._hinter.position.y = -(this.cameraHeightInPixel - selectedPoint.y)
                this._hinter.position.z = selectedPoint.z

                this.hintChangeEvent.isValid = true
                this.hintChangeEvent.lon = selectedPoint.lon
                this.hintChangeEvent.lat = selectedPoint.lat

                this.currentHint = selectedPoint
                this.throwEvent(this.hintChangeEvent)
            }

            var angg = (Math.PI * 2) - (Math.atan2(selectedPoint.dz, selectedPoint.dx) + (Math.PI / 2))
            this._tm.rotation.x = -Math.PI / 2
            this._tm.rotation.z = angg
            this._arrowPlane.visible = true
            this._hinter.visible = true
        } else {
            if (this.currentHint) {
                this.currentHint = null
                this.hintChangeEvent.isValid = false
                this.hintChangeEvent.lon = null
                this.hintChangeEvent.lat = null
                this.throwEvent(this.hintChangeEvent)
            }
            this._hinter.visible = false
            this._arrowPlane.visible = false
        }

        this._crossPlane.visible = !this._arrowPlane.visible
    } else {
        if (this.currentHint) {
            this.currentHint = null
            this.hintChangeEvent.isValid = false
            this.hintChangeEvent.lon = null
            this.hintChangeEvent.lat = null
            this.throwEvent(this.hintChangeEvent)
        }
        selectedPoint = null
    }
}

GroundMaster.prototype.onMouseClick = function (e) {
    if (!PanoGL.isPointerOnClickableObject && this._destPoint && this.allowClick) {
        var evt = this._clickEvent
        evt.lat = this._destPoint.lat
        evt.lon = this._destPoint.lon
        evt.alt = this._destPoint.alt
        evt.pos = this.lastPos

        this.throwEvent(evt)
    }

    this.allowClick = true
}

GroundMaster.prototype.addWall = function (mesh) {
    this.__wallGeometries.push(mesh)
}

GroundMaster.prototype.removeWall = function (mesh) {
    var i = this.__wallGeometries.indexOf(mesh)
    if (i > -1) {
        this.__wallGeometries.splice(i, 1)
    }
}

export { GroundMaster }
