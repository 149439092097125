import { Base } from './Base'
import { DataParser } from './DataParser'
import { PanoromicController } from './PanoromicController'
import { MeshMousePicker } from './MeshMousePicker'
import { ArrowManager } from './ArrowManager'
import { LabelManager } from './LabelManager'
import { GroundLabelManager } from './GroundLabelManager'
import { PanoSphere } from './PanoSphere'
import { GroundMaster } from './GroundMaster'
import { Compass } from './Compass'
import { Renderer } from './Renderer'
import { Utils, TO_RAD, TO_DEG } from './Utils'

var PanoGL = (function () {
    PanoGL.GLOBAL_CONFIG = {
        // ANTIALIAS is not supported some graphic cards. So that before initialize PanoGL,  you may want to flag this false.
        ANTIALIAS: true,
        PRESERVED_BUFFER: true,
        LINE_TOUCH_TICKNESS: 1,
        RECORD_DISTANCE: 5
    }

    PanoGL.MOUSE_GROUND_LEFT = 'onMouseGroundLeft'
    PanoGL.MOUSE_GLOBAL_GROUND_LEFT = 'onMouseGlobalGroundLeft'
    PanoGL.PANOGL_OBJECTS_CREATION_COMPLETED = 'onPanoGlObjectsCreationComplete'
    PanoGL.THUMB_IMAGE_DISPLAYED = 'onThumImageDisplayed'
    PanoGL.PRE_RENDER = 'onPreRender'
    PanoGL.POST_RENDER = 'onPostRender'
    PanoGL.RENDER_AFTER_SPHERE = 'onRenderAfterSphere'
    PanoGL.RENDER_AFTER_GROUND = 'onRenderAfterGround'
    PanoGL.ORIENTATION_CHANGED = 'onOrientationChanged'
    PanoGL.DATA_CHANGE = 'onDataChange'
    PanoGL.LOCATION_CHANGE = 'locationChanged'
    PanoGL.CONTROLLER_UPDATE = 'onControllerUpdate'
    PanoGL.DATA_COMPLETE = 'onDataComplate'
    PanoGL.SETUP_COMLETE = 'onSetupComplete'
    PanoGL.SETUP_COMPLETE = 'onSetupComplete'
    PanoGL.RESIZE = 'onResize'
    PanoGL.VIEW_ANGLE_CHANGE = 'onViewAngleChange'
    PanoGL.MOUSE_MOVE_ON_GROUND = 'onCursorMoveOnGround'
    PanoGL.MOUSE_MOVE2D = 'mouseMove2d'
    PanoGL.CLICK2D = 'click2d'
    PanoGL.SAFE_CLICK2D = 'safeClick2d'
    PanoGL.CONTEXT_CLICK = 'onContextClick'
    PanoGL.RENDER_DOM_CLICK = 'onRenderDomClick'
    PanoGL.RENDER_DOM_MOUSE_DOWN = 'onRenderDomMouseDown'
    PanoGL.MOUSE_LEAVE = 'onMouseLeave'
    PanoGL.POINTER_GUI_OVER = 'onPointerGUIOver'
    PanoGL.POINTER_GUI_OUT = 'onPointerGUIOut'
    PanoGL.STATE_CHANGE = 'onStateChange'
    PanoGL.waitingForGlobalRender = false
    PanoGL.__isPointerOnClickableObject = false

    PanoGL.prototype = Object.create(Base.prototype)
    PanoGL.prototype.constructor = PanoGL
    //     /**
    //  * Tile olan panaromalar için true olarak atanması gerekiyor. Default value false.
    //  * @property {Boolean} isTiled
    //  * @type String
    //  * @static
    //  * @deprecated "pano.isTiled kullan"
    //  */
    //     PanoGL.isTiled = false
    // divName, jsonConenction, ifolder, tfolder, labelStyle, options
    function PanoGL(options) {
        Base.apply(this, [])
        if (arguments.length === 1) {
            let { content, aroundService, imageService, labelStyle } = options
            if (typeof content === 'string') {
                this._divName = content
            } else {
                this.contentDV = content
            }
            this.jsonConenction = aroundService
            this.ifolder = imageService
            this.labelStyle = labelStyle
        } else {
            console.warn('div, aroundService, imageService, tileService, labelStyle and options arguments are deprecated.\nUse options{} instead!')
            this._divName = arguments[0]
            this.jsonConenction = arguments[1]
            this.ifolder = arguments[2]
            this.tfolder = arguments[3]
            this.labelStyle = arguments[4]
        }


        // this.resizeAll = this.resizeAll.bind(true);
        this._autoUpdateSigns = true
        this._mouseRayVector = new THREE.Vector3()
        this._rayCaster = new THREE.Raycaster()
        this.userData = {}
        this.allowSecondImage = true
        this.filterImage = undefined
        this.thumbSize = { x: 512, y: 256 }
        this._isGroundLabelVisible = true
        this._isCompassVisible = true
        this._isLabelVisible = true
        this._isArrowVisible = true
        this.plugins = []
        this._ambianceLights = []
        this.assignedPanos = []
        this.initialOptions = options
        if (options !== undefined) {
            for (var key in options) {
                this['_' + key] = options[key]
            }
        }

        if (!PanoGL._instances) {
            PanoGL._instances = []
        }
        this.isDirty = true
        PanoGL._instances.push(this)
        PanoGL.isMobile = Utils.isMobileOrTablet()// Utils.isTouchDevice();
        this._resize = this.onResize.bind(this)
        window.addEventListener('resize', this._resize)
        document.addEventListener('resize', this._resize)

        if (this.getUrlFactory() === undefined) {
            this.setUrlFactory(new PanoGL.URLFactory())
        }
    }

    Object.defineProperty(PanoGL, 'isPointerOnClickableObject', {
        get: function () {
            return PanoGL.__isPointerOnClickableObject
        },
        set: function (v) {
            if (PanoGL.__isPointerOnClickableObject !== v) {
                PanoGL.__isPointerOnClickableObject = v
                var ins = AnkaPanAPI.PanoGL._instances
                for (var i = 0; i < ins.length; i++) {
                    if (v) {
                        ins[i].throwEvent({ type: PanoGL.POINTER_GUI_OVER })
                    } else {
                        ins[i].throwEvent({ type: PanoGL.POINTER_GUI_OUT })
                    }
                }
            }
        }
    })

    PanoGL.prototype.setSphereVisbility = function (b) {
        let sphere = this.mainPanoSphere
        if (sphere) {
            sphere.setSphereVisbility(b)
            this.setDirty()
        }
    }

    /**
    * URL generator
    * @method setUrlFactory
    * @memberof PanoGL
    * @instance
    */
    PanoGL.prototype.setUrlFactory = function (factory) {
        this._factory = factory
    }

    PanoGL.prototype.getUrlFactory = function () {
        return this._factory
    }

    /**
     * Camera looks at the degre respected to azimuth
    * @method lookAtY
    * @param {Number} degree
    * @memberof PanoGL
    * @instance
    */
    PanoGL.prototype.lookAtY = function (degree) {
        this.mainCam.rotation.y = (360 - degree) * TO_RAD
        this.setDirty()

        var event = { type: PanoGL.VIEW_ANGLE_CHANGE }
        event.rotationY = this.mainCam.rotation.y * TO_DEG
        event.rotationX = this.mainCam.rotation.x * TO_DEG
        event.fov = this.getController().getCurrentFovRatio()
        this.throwEvent(event)
    }

    /**
     * Current position's data. if null, no data recieved yet.
     * @method getCurrentPoint
     * @memberof PanoGL
     * @instance
     */
    PanoGL.prototype.getCurrentPoint = function () {
        if (this.dataParser && this.dataParser.currentPoint) {
            return this.dataParser.currentPoint
        }
        return null
    }

    PanoGL.prototype.getOtherData = function () {
        if (this.dataParser && this.dataParser.otherPoints) {
            return this.dataParser.otherPoints
        }
        return null
    }

    PanoGL.prototype.start = function () {
        if (this.isStarted) {
            return
        }
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            this.init()
        } else if (document.readyState === 'loading') {
            window.addEventListener('load', this.init.bind(this))
        }
        this.isStarted = true
    }

    /**
    * init function starts setups for ThreeJS obecjts (Scene, Camera) and PanoGL objects (Camera Controller, Sphere)
    * manual call is not suggested
    * @method init
    * @instance
    * @memberof PanoGL
    */
    PanoGL.prototype.init = function () {
        this.setupThreeJS()
        this._setupPanoGLObjects()
        Renderer.getInstance().addPanoGLInstance(this)
        if (this.nexLat !== undefined && this.nexLon !== undefined) {
            this.gotoLocation(this.nexLat, this.nexLon)
        }

        this.throwEvent({ type: PanoGL.SETUP_COMLETE, target: this })
        var t = this
        t.contentDV.addEventListener('contextmenu', function (event) {
            event.preventDefault()
            t.throwEvent({ type: PanoGL.CONTEXT_CLICK, regularEvent: event })
        })
    }

    PanoGL.prototype._setupPanoGLObjects = function () {
        let isMoved = false
        let clientX
        let clientY
        let renderDom = this.getRendererDom()
        renderDom.addEventListener('mousemove', (event) => {
            if (clientX !== undefined) {
                isMoved = Math.abs(clientY - event.clientY) > 2 || Math.abs(clientX - event.clientX) > 2
            }
            this.throwEvent({
                type: PanoGL.MOUSE_MOVE2D,
                ray: this.getMouseRay(),
                nativeEvent: event,
                onGui: PanoGL.isPointerOnClickableObject
            })
        })

        renderDom.addEventListener('click', (event) => {
            this.throwEvent({
                type: PanoGL.CLICK2D,
                ray: this.getMouseRay(),
                nativeEvent: event
            })
        })

        renderDom.addEventListener('mousedown', (event) => {
            isMoved = false
            clientX = event.clientX
            clientY = event.clientY
        })

        document.addEventListener('mouseup', (event) => {
            if (!isMoved) {
                this.throwEvent({
                    active: this.isUserActive,
                    type: PanoGL.SAFE_CLICK2D,
                    onGui: PanoGL.isPointerOnClickableObject,
                    ray: this.getMouseRay(),
                    nativeEvent: event
                })
            }
            clientX = undefined
            clientY = undefined
        })

        var meshMp = MeshMousePicker.getInstance()
        meshMp.registerPanoGL(this)

        this.mainPanoSphere = new PanoSphere(this)
        this.mainScene.add(this.mainPanoSphere)

        this.arrowCamera.name = 'arrowCamera_' + this.uid

        this.arrowManager = new ArrowManager(this, this.arrowManagerScene, this.arrowCamera)
        this.arrowManager.addEvent('arrowClicked', this, this.onArrowClicked)
        this.arrowManagerScene.add(this.arrowManager)

        this.labelManager = new LabelManager(this, this.arrowManager)
        this.labelManagerScene.add(this.labelManager)

        this.groundLabelManager = new GroundLabelManager(this)

        this.groundLabelManager.scene.visible = this._isGroundLabelVisible

        this.dataParser = new DataParser(this, this.jsonConenction, this.ifolder)
        this.dataParser.addEvent(DataParser.DATA_LOADED, this, this.onDataComplete)

        this._createGroundMaster()

        if (this._isCompassVisible) {
            this.compass = new Compass(renderDom)
        }

        this.controller = this.getController()
        this.initialized = true
        this.throwEvent({ type: PanoGL.PANOGL_OBJECTS_CREATION_COMPLETED })
    }

    PanoGL.prototype.addWall = function (mesh) {
        this.groundMaster.addWall(mesh)
    }

    PanoGL.prototype.removeWall = function (mesh) {
        this.groundMaster.removeWall(mesh)
    }

    /**
     * creates groundMaster
     * @method _createGroundMaster
     * @memberof PanoGL
     * @protected
     */
    PanoGL.prototype._createGroundMaster = function () {
        this.groundMaster = new GroundMaster(this, this.renderer.domElement, this.mainPanoSphere, this.mainCam, this.renderer)
        this.groundMaster.addEvent(GroundMaster.GROUND_CLICK, this, this.onGroundClick)
        this.groundMaster.addEvent(PanoGL.MOUSE_MOVE_ON_GROUND, this, this.onCursorMoveOnGround)
        this.groundMaster.addEvent('onHintChangeEvent', this, this.onHintChangeEvent)
        this.groundMaster.addEvent(GroundMaster.GROUND_MOUSE_LEFT, this, this.onGroundLeft)
        this.groundMaster.addEvent(GroundMaster.GROUND_MOUSE_LEFT, this, this.onGroundLeftForAll)
        this.groundMaster.setCameraHeight(2.2)
    }

    /**
     * throws an "MOUSE_GROUND_LEFT" event. manually usage can cause wrong workings.
     * @method onGroundLeft
     * @memberof PanoGL
     * @public
     * @instance
     */
    PanoGL.prototype.onGroundLeft = function () {
        this.throwEvent({ type: PanoGL.MOUSE_GROUND_LEFT, target: this })
    }

    /**
     * throws an "MOUSE_GROUND_LEFT" event for all PanoGL instances. manually usage can cause wrong workings.
     * @method onGroundLeftForAll
     * @memberof PanoGL
     * @public
     * @instance
     */
    PanoGL.prototype.onGroundLeftForAll = function () {
        var instances = PanoGL._instances
        for (var i = 0; i < instances.length; i++) {
            instances[i].throwEvent({ type: PanoGL.MOUSE_GLOBAL_GROUND_LEFT, target: instances[i] })
        }
    }

    /**
     * gives current camera height
     * @method getCameraHeight
     * @return {Number}
     * @memberof PanoGL
     * @instance
     */
    PanoGL.prototype.getCameraHeight = function () {
        return this.groundMaster.getCameraHeight()
    }

    /**
     * get ground position in px (Car's ground)
     * @method getGroundPos
     * @return {Number}
     * @memberof PanoGL
     * @instance
     */
    PanoGL.prototype.getGroundPos = function () {
        return this.groundMaster.getGroundPos()
    }

    /**
     * Shows panorama in fullScreen mode.
     * @method requestFullscreen
     * @return {void}
     * @memberof PanoGL
     * @instance
     */
    PanoGL.prototype.requestFullscreen = function () {
        var element = this.contentDV
        if (element.requestFullscreen) {
            element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen()
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen()
        }
    }

    PanoGL.prototype.setPlugin = function (plugin) {
        this.plugins === undefined ? this.plugins = [plugin] : this.plugins.push(plugin)
        plugin.install(this)
    }

    /**
     * return array of plugins. Modifying return array can cause bugs.
     * @method getPlugins
     * @memberof PanoGL
     * @return {Array}
     * @instance
     */
    PanoGL.prototype.getPlugins = function () {
        return this.plugins
    }

    /**
     * gets URL for requesting points.  Method uses {URLFactory}
     * @method getRequestURL
     * @memberof PanoGL
     * @return {String}
     * @instance
     */
    PanoGL.prototype.getRequestURL = function (lon, lat) {
        return this._factory.getRequestURL(this, lon, lat)
    }

    PanoGL.prototype.getRendererDom = function () {
        if (this.renderer) {
            return this.renderer.domElement
        }
        return null
    }

    PanoGL.prototype.getMainCamera = function () {
        return this.mainCam
    }

    PanoGL.prototype.getRenderer = function () {
        return this.renderer
    }

    /**
     * Controller getter
     * @method getController
     * @memberof PanoGL
     * @return {PanoromicController}
     * @instance
     */
    PanoGL.prototype.getController = function () {
        if (!this.controller) {
            this.controller = new PanoromicController(this, this.mainPanoSphere, this.mainCam, this.renderer.domElement)
            this.controller.addEvent(PanoGL.VIEW_ANGLE_CHANGE, this, this.onViewAngleChange)
        }
        return this.controller
    }

    /**
 * ThreeJS API'nin çalışabilmesi için gerekli hazırlıklar ve sahneler bu alanda kuruluyor.
 * @method setupThreeJS
 * @memberof PanoGL
 * @instance
 **/
    PanoGL.prototype.setupThreeJS = function () {
        this.prepareCanvasDiv()
        this.resizeAll()

        this.mainScene = new THREE.Scene()

        var light = new THREE.AmbientLight(0xffffff) // soft white light
        light.name = 'mainLight'
        this.mainScene.add(light)
        this.screenLight = light
        this.addAmbientLight(light)

        this.markerContailer = new THREE.Scene()
        this.arrowManagerScene = new THREE.Scene()
        this.labelManagerScene = new THREE.Scene()

        this.mainCam = new THREE.PerspectiveCamera(75, this.aspRatio, 0.1, 90000)
        this.arrowCamera = new THREE.PerspectiveCamera(75, this.aspRatio, 0.1, 1500)

        this.renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: PanoGL.GLOBAL_CONFIG.PRESERVED_BUFFER, antialias: PanoGL.GLOBAL_CONFIG.ANTIALIAS })
        this.renderer.autoClear = false
        this.renderer.setSize(this.stageWidth, this.stageHeight)

        this.contentDV.appendChild(this.renderer.domElement)
        this.renderer.domElement.id = this.uid

        var t = this
        this.renderer.domElement.addEventListener('click', function (e) {
            t.throwEvent({ type: PanoGL.RENDER_DOM_CLICK, event: e })
        })

        this.renderer.domElement.addEventListener('mousedown', function (e) {
            t.throwEvent({ type: PanoGL.RENDER_DOM_MOUSE_DOWN, event: e })
        })
    }

    /**
    * @method prepareCanvasDiv
    * @memberof PanoGL
    * @instance
    * @example
    * Constructor'da tanımlanan div kullanılarak, düzenleme yapılır.</br>
    * style
    * overflow:"hidden";
    * width:"100%";
    * height:"100%";
    * position:"relative";
    * Method çalıştırılması ile interval 0.2 saniye ara ile interval çalıştırılıp, div ölçüleri kontrol edilir.
    **/
    PanoGL.prototype.prepareCanvasDiv = function () {
        if (this.contentDV === undefined) {
            this.contentDV = document.getElementById(this._divName)
            if (!this.contentDV) {
                throw new Error(this.contentDV + " ID'li div bulanamadı.")
            }
        }

        this.contentDV.style.overflow = 'hidden'
        this.contentDV.style.height = '100%'
        this.contentDV.style.width = '100%'
        this.contentDV.style.position = 'relative'
        // TODO CHECKED this.checkParentSizeAllTheTime()
        // console.warn('checkParentSizeAllTheTime() pethod is not triggered automatically due to rendering bottle neck reasons. Use manually or use resizeAll() method for all UI changes!')

        var t = this
        this.isUserActive = false
        this.contentDV.addEventListener('mousemove', function (e) {
            t.GlobalMouseEvent = e
            t.globalMouseOffsetX = e.offsetX
            t.globalMouseOffsetY = e.offsetY
            t.isUserActive = true
        })

        this.contentDV.addEventListener('mouseleave', function (e) {
            t.isUserActive = false
            t.throwEvent({ type: PanoGL.MOUSE_LEAVE })
        })

        this.contentDV.addEventListener('mouseenter', function (e) {
            t.isUserActive = true
        })
    }

    /**
    * creates a software canvas and disable all mouseevents. This is useful for future, smooth drawings.
    * @method createSoftwareCanvas
    * @memberof PanoGL
    * @deprecated
    * @instance
    */
    PanoGL.prototype.createSoftwareCanvas = function () {
        this.softCanvas = document.createElement('canvas')
        this.softContext = this.softCanvas.getContext('2d')
        this.softCanvas.id = 'softPanoTextRender' + Math.floor(Math.random() * 999999)
        var s = this.softCanvas.style
        s.position = 'absolute'
        s.top = '0px'
        s.left = '0px'
        s.pointerEvents = 'none'
        s.backgroundColor = 'transparent'
        this.contentDV.appendChild(this.softCanvas)

        this.resizeAll()
        return this.softContext
    }

    // TODO will be removed on release
    PanoGL.prototype.onDataComplate = function () { throw new Error('event handler is removed use onDataComplete.') }

    /**
     * Data load event function
     * @method onDataComplete
     * @param  {Object} e json data
     * @memberof PanoGL
     * @instance
     */
    PanoGL.prototype.onDataComplete = function (e) { // TODO needs to be private

        let dataParser = this.dataParser
        this._filteredArrows = ArrowManager.filterArrow(dataParser.currentPoint, dataParser.otherPoints)
        let mainPanoSphere = this.mainPanoSphere

        if (!mainPanoSphere.isColor()) {
            var img = this.getCurrentImagePath()
            // img = 'http://localhost:8080/examples/test_img/go_pro.jpeg'
            mainPanoSphere.setThumbImage(img)
            mainPanoSphere.addEvent(PanoSphere.IMAGE_LOADED, this, this.onThumbImageLoaded)
        } else {
            if (this._autoUpdateSigns) {
                this.updateSigns()
            }

            this.groundMaster.updateMouseCoordinates()
        }

        this.throwEvent({ data: e.data, type: PanoGL.DATA_COMPLETE })
    }

    PanoGL.prototype.getCurrentImagePath = function () {
        return this._factory.getThumbPath(this, { current: this.getCurrentPoint() })
    }

    Object.defineProperty(PanoGL.prototype, 'autoUpdateSigns', {
        get: function () {
            return this._autoUpdateSigns
        },
        set: function (v) {
            this._autoUpdateSigns = v
        }
    })

    PanoGL.prototype.updateSigns = function () {
        this._setArrows(this.dataParser)
        this._setGroundLabels()
    }

    /**
     * gets tile paths
     * @method getTilePath
     * @param  {Object} e json data
     * @memberof PanoGL
     * @deprecated
     * @instance
    */
    PanoGL.prototype.getTilePath = function (tileImage) {
        if (!this.dataParser.currentPoint) return ''

        var m = Utils.mergeStringForUrl
        var dc = this.dataParser.currentPoint
        var img
        if (dc.parent) {
            img = m(m(m(m(m(this.tfolder, dc.parent), dc.dirname), '/8192/4096'), tileImage), dc.img)
        } else {
            img = m(m(m(m(this.tfolder, dc.dirname), '/8192/4096'), tileImage), dc.img)
        }

        return img
    }

    /**
     * After first image loads, this function starts second image load if it is allowed, jobs of filtering and setting arrows, ground labels, and also throw PanoGL.THUMB_IMAGE_DISPLAYED & orientation settings
     * @method onThumbImageLoaded
     * @param  {Object} e Event data
     * @memberof PanoGL
     * @private
     * @instance
     */
    PanoGL.prototype.onThumbImageLoaded = function () {
        var data = this.dataParser.currentPoint
        this.setOriantation(data.heading, data.pitch, data.roll)
        this.mainPanoSphere.removeEvent(PanoSphere.IMAGE_LOADED, this.onThumbImageLoaded)
        this.throwEvent({ type: PanoGL.DATA_CHANGE, data: data })

        if (this._autoUpdateSigns) {
            this._setArrows(this.dataParser)
            this._setGroundLabels()
        }

        var _locationChanged = { type: PanoGL.LOCATION_CHANGE }
        _locationChanged.lat = data.lat
        _locationChanged.lon = data.lon

        this.groundMaster.updateMouseCoordinates()
        this.throwEvent({ type: PanoGL.THUMB_IMAGE_DISPLAYED })
        this.throwEvent(_locationChanged)
    }

    PanoGL.prototype._setArrows = function (dataParser) {
        this.arrowManager.removeAll()
        this.arrowManager.addArrows(this._filteredArrows)
        this.groundMaster.setCurrentData(dataParser.currentPoint)
        this.groundMaster.addStopPoints(dataParser.otherPoints)
    }

    PanoGL.prototype._setGroundLabels = function () {
        if (this._isGroundLabelVisible) {
            this.groundLabelManager.setData(this._filteredArrows)
        }
    }

    PanoGL.prototype.setOriantation = function (heading, pitch, roll) {
        var radPitch = pitch * TO_RAD
        var radHeading = heading * TO_RAD
        var radRoll = roll * TO_RAD
        this.mainPanoSphere.setOriantation(radHeading, radPitch, radRoll)
        this.throwEvent({ type: PanoGL.ORIENTATION_CHANGED, pitch: radPitch, heading: radHeading, roll: radRoll })
    }

    PanoGL.prototype.getGroundMaster = function () {
        return this.groundMaster
    }

    /**
     * Event function of Angle changes on PanoromicController instance
     * @method getGroundMaster
     * @return {GroundMaster} GroundMaster instance.
     * @memberof PanoGL
     * @instance
     */
    PanoGL.prototype.onViewAngleChange = function (e) {
        if (this.hasEvent(e.type)) {
            var rotY = 360 - e.rotationY
            if (rotY < 0) {
                rotY += 360
            }
            rotY %= 360
            var rotX = (e.rotationX < 0 ? e.rotationX + 360 : e.rotationX)
            rotX %= 360
            var obj = { type: e.type, rotationY: rotY, rotationX: rotX, fov: e.fov }
            this.throwEvent(obj)
        }
    }

    PanoGL.prototype.getMouseRay = function () {
        return this._rayCaster.ray
    }

    PanoGL.prototype.update = function () {
        let canvasElement = this.getRendererDom()
        let rayCaster = this._rayCaster
        let mouseVector = this._mouseRayVector
        mouseVector.x = (this.globalMouseOffsetX / canvasElement.width) * 2 - 1
        mouseVector.y = -(this.globalMouseOffsetY / canvasElement.height) * 2 + 1
        rayCaster.setFromCamera(mouseVector, this.mainCam)

        var allowRender = false
        this.throwEvent({ type: PanoGL.PRE_RENDER })

        if (this.groundMaster.isDirty || this.isDirty) {
            allowRender = true
            this.groundMaster.update()
        }

        if (this.controller.isDirty || this.isDirty) {
            allowRender = true
            this.controller.update()

            this.throwEvent({ type: PanoGL.CONTROLLER_UPDATE })

            if (this._isArrowVisible) {
                this.arrowManager.update()

                if (this._isLabelVisible) this.labelManager.update()
            }

            if (this.compass) {
                this.compass.setDegree(this.controller.getRotationYDeg())
            }
        }

        if (this.mainPanoSphere.isDirty || this.isDirty) {
            this.mainPanoSphere.update()
        }

        this.plugins.forEach(plugin => {
            if (plugin.isDirty) {
                allowRender = true
                plugin.update()
            }
        })

        if (allowRender || this.isDirty || PanoGL.waitingForGlobalRender) {
            var renderer = this.renderer
            renderer.clear()
            renderer.render(this.mainScene, this.mainCam)

            this.throwEvent({ type: PanoGL.RENDER_AFTER_SPHERE })

            if (this.groundLabelManager && this._isGroundLabelVisible) {
                this.groundLabelManager.render()
            }

            // renderer.clearDepth()
            renderer.render(this.markerContailer, this.mainCam)

            this.throwEvent({ type: PanoGL.RENDER_AFTER_GROUND })

            if (this._isArrowVisible) {
                // renderer.clearDepth()
                renderer.render(this.arrowManagerScene, this.arrowCamera)

                if (this._isLabelVisible) {
                    // renderer.clearDepth()
                    renderer.render(this.labelManagerScene, this.arrowCamera)
                }
            }

            this.throwEvent({ type: PanoGL.POST_RENDER })
            PanoGL.waitingForGlobalRender = false
            this.isDirty = false
        }
    }

    /**
     * @deprecated
     * TODO remove later
     */
    PanoGL.prototype.setSoftCanvasDirty = function () {
        this._isSoftCanvasDirty = true
    }

    PanoGL.prototype.setDirty = function () {
        this.isDirty = true
    }

    PanoGL.prototype.setCursorPosition = function (lon, lat, alt, cameraLookAt, isWall, normal) {
        var ob = this.getPosFromLocation(lon, lat, alt)
        if (ob) {
            this.groundMaster.show3DCursorOnMap(ob.x, ob.y, ob.z, isWall, normal)
            if (cameraLookAt) {
                this.getController().lookAt(new THREE.Vector3(ob.x, ob.y, ob.z))
            }
        }
    }

    PanoGL.prototype.getPosFromLocation = function (lon, lat, alt) {
        if (!this.dataParser || !this.dataParser.currentPoint) return null

        var _currentData = this.dataParser.currentPoint
        var dist = Utils.heversine(_currentData.lat, lat, _currentData.lon, lon)

        if (dist > 45) return null

        if (!_currentData) return null

        var azimuth = ((Utils.bearing(_currentData.lat, _currentData.lon, lat, lon))) % 360
        azimuth = (360 - azimuth) % 360

        var theta = azimuth

        var gm = this.groundMaster
        var hip = (dist * Math.abs(gm.cameraHeightInPixel)) / gm.cameraHeight
        var rad = (360 - (theta + 90)) * TO_RAD
        var xx = hip * Math.cos(rad)
        var zz = hip * Math.sin(rad)
        var yy = -gm.cameraHeightInPixel

        if (alt !== undefined && alt !== null) {
            alt = alt - _currentData.altitude
            alt = alt / gm.cameraHeight
            yy = alt * gm.cameraHeightInPixel
        }

        return { x: xx, y: yy, z: zz }
    }

    /**
     * Event function appented to Arrow objects.
     * @method onArrowClicked
     * @param  {Object} e Event data. Contains some Arrow data beside coordinates.
     * @memberof PanoGL
     * @instance
     * @private
     */
    PanoGL.prototype.onArrowClicked = function (e) {
        this.gotoLocation(e.data.lat, e.data.lon)
    }

    /**
     * Resize event function. Function triggers resizeAll method twive with a 200 ms delay. If worryings about performance, use resizeAll instead.  Otherwise, this can be better solution because of slow render of CSS and DOMS
     * @method onResize
     * @memberof PanoGL
     * @public
     * @instance
     */
    PanoGL.prototype.onResize = function () {
        if (this.intiRes) {
            clearTimeout(this.intiRes)
        }

        this.resizeAll()
        this.intiRes = setTimeout(this.resizeAll.bind(this), 200)
    }

    /**
     * starts an Auto resize and works every miliseconds you sent as param.
     * @method startAutoResize
     * @memberof PanoGL
     * @param {Number} mil if undefined, default will be 500ms.
     * @public
     * @instance
     */
    PanoGL.startAutoResize = function (miliseconds) {
        PanoGL.stopAutoResize()
        PanoGL.resizeAllInstance()
        var ms = miliseconds === undefined ? 500 : miliseconds
        PanoGL.___intResize = setTimeout(PanoGL.startAutoResize, ms)
    }

    /**
     * kills an Auto resize.
     * @method stopAutoResize
     * @memberof PanoGL
     * @public
     * @instance
     */
    PanoGL.stopAutoResize = function (mil) {
        if (PanoGL.___intResize) {
            clearTimeout(PanoGL.___intResize)
        }
    }

    PanoGL.resizeAllInstance = function () {
        var instances = PanoGL._instances
        for (var i = 0; i < instances.length; i++) {
            instances[i].resizeAll()
        }
    }

    PanoGL.getInstanceCount = function () {
        return PanoGL._instances.length
    }

    PanoGL.getPanoAt = function (n) {
        if (n < 0) {
            console.error('Index number cannot be smaller than 0')
        } else if (n > PanoGL._instances.length - 1) {
            console.error('Index number cannot be bigger than instance count')
        } else {
            return PanoGL._instances[n]
        }
    }

    /**
     * resize PanoGL render dom and throw resize event for other users. If no changes, Resize will not be applied.
     * @method resizeAll
     * @memberof PanoGL
     * @public
     * @instance
    */
    PanoGL.prototype.resizeAll = function () {
        var cdv = this.contentDV

        if (this.stageWidth !== cdv.clientWidth || this.stageHeight !== cdv.clientHeight) {
            this.stageWidth = cdv.clientWidth
            this.stageHeight = cdv.clientHeight
            this.aspRatio = this.stageWidth / this.stageHeight

            if (this.renderer) {
                this.renderer.setSize(this.stageWidth, this.stageHeight)
            }

            if (this.mainCam) {
                this.mainCam.aspect = this.stageWidth / this.stageHeight
                this.mainCam.updateProjectionMatrix()
            }

            if (this.arrowCamera) {
                this.arrowCamera.aspect = this.stageWidth / this.stageHeight
                this.arrowCamera.updateProjectionMatrix()
            }

            this.isDirty = true
            this.throwEvent({ type: PanoGL.RESIZE, width: this.stageWidth, height: this.stageHeight, aspectRatio: this.aspRatio })
        }

        if (this.softCanvas) {
            if (this.softCanvas.width !== this.stageWidth || this.softCanvas.height !== this.stageHeight) {
                this.softCanvas.width = this.stageWidth
                this.softCanvas.height = this.stageHeight
            }
        }
    }

    PanoGL.prototype.gotoLocation = function (lat, lon) {
        if (this.dataParser) {
            this.dataParser.sendPointRequest(lat, lon)
        } else {
            this.nexLat = lat
            this.nexLon = lon
        }
    }

    /**
     * Clear all ThreeJS texture cache. This can be usefull for limited resources. this may effect other instances.
     * @method clearThreeCache
     * @memberof PanoGL
     * @public
     * @instance
     */
    PanoGL.prototype.clearThreeCache = function () {
        for (var str in THREE.Cache.files) {
            delete THREE.Cache.files[str]
        }
    }

    /**
     * event function of GroundMaster Click.
     * @method onGroundClick
     * @memberof PanoGL
     * @private
     * @instance
     */
    PanoGL.prototype.onGroundClick = function (e) {
        if (!this._groundClickDisabled) {
            this.gotoLocation(e.lat, e.lon)
        }

        if (this.hasEvent(GroundMaster.GROUND_CLICK)) {
            this.throwEvent({ type: e.type, lat: e.lat, lon: e.lon })
        }
    }

    /**
 * Disables/Enables ground click navigation.
 * @method disableGroundNav
 * @memberof PanoGL
 * @param {Boolean} b
 * @private
 * @instance
 */
    PanoGL.prototype.disableGroundNav = function (b) {
        this._groundClickDisabled = b
    }

    /**
 * PanoGL applying of Mouse of ground event. throws event that gives  type, lat, lon, alt, isWall and normal for walls.
 * @method onCursorMoveOnGround
 * @memberof PanoGL
 * @param {Object} e
 * @private
 * @instance
 */
    PanoGL.prototype.onCursorMoveOnGround = function (e) {
        if (this.hasEvent(PanoGL.MOUSE_MOVE_ON_GROUND)) {
            this.throwEvent({ type: e.type, lat: e.lat, lon: e.lon, alt: e.alt, isWall: e.isWall, normal: e.normal })
        }
    }

    /**
 * PanoGL applying of hint change of GroundMaster.
 * Hints are the points that shows where Panorama will go to.
 * @method onHintChangeEvent
 * @memberof PanoGL
 * @param {Object} e
 * @private
 * @instance
 */
    PanoGL.prototype.onHintChangeEvent = function (e) {
        if (this.hasEvent('onHintChangeEvent')) {
            this.throwEvent({ type: e.type, lat: e.lat, lon: e.lon, isValid: e.isValid })
        }
    }

    PanoGL.prototype.getPhoto = function () {
        window.open(this.renderer.domElement.toDataURL('image/png'), 'screenshot')
    }

    /**
     * Checks parent sizes and if something changes, resize automatically
     * @method checkParentSizeAllTheTime
     * @memberof PanoGL
     * @public
     * @instance
     */
    PanoGL.prototype.checkParentSizeAllTheTime = function () {
        clearInterval(this.intervalIDHolder)
        this._prevWid = this.contentDV.offsetWidth
        this._prevHeg = this.contentDV.offsetHeight
        this.intervalIDHolder = setInterval(this.checkParentSize.bind(this), 200)
    }

    /**
     * Checks parent sizes resize with every call
     * @method checkParentSize
     * @memberof PanoGL
     * @public
     * @instance
     */
    PanoGL.prototype.checkParentSize = function () {
        var width = this.contentDV.clientWidth
        var height = this.contentDV.clientHeight
        if (this._prevWid !== width || this._prevHeg !== height) {
            this._prevWid = width
            this._prevHeg = height
            this.resizeAll()
        }
    }

    PanoGL.prototype.setBrightness = function (v) {
        for (var i = 0; i < this._ambianceLights.length; i++) {
            this._ambianceLights[i].intensity = v
        }
        this.setDirty()
    }

    PanoGL.prototype.getBrightness = function () {
        for (var i = 0; i < this._ambianceLights.length; i++) {
            return this._ambianceLights[i].intensity
        }
    }


    PanoGL.prototype.addAmbientLight = function (light) {
        this._ambianceLights.push(light)
    }

    PanoGL.prototype.requestNextFrame = function (divname, options) {
        var cp = this.dataParser.currentPoint
        var currentFrame = cp.frame
        var dirname = cp.dirname
        var otherPoints = this.dataParser.otherPoints
        var tempFrame = currentFrame + 1

        var point
        for (var i = 0; i < otherPoints.length; i++) {
            if (otherPoints[i].dirname === dirname && tempFrame === otherPoints[i].frame) {
                point = otherPoints[i]
            }
        }

        var pano = this.createConnectedPano(divname, options)
        if (!point) {
            point = Utils.destinationPoint(cp.lat, cp.lon, PanoGL.GLOBAL_CONFIG.RECORD_DISTANCE, cp.heading)
        }

        pano.gotoLocation(point.lat, point.lon)

        return pano
    }

    PanoGL.prototype.requestPreviousFrame = function (divname, options) {
        var cp = this.dataParser.currentPoint
        var currentFrame = cp.frame
        var dirname = cp.dirname
        var otherPoints = this.dataParser.otherPoints
        var tempFrame = currentFrame - 1

        var point
        for (var i = 0; i < otherPoints.length; i++) {
            if (otherPoints[i].dirname === dirname && tempFrame === otherPoints[i].frame) {
                point = otherPoints[i]
            }
        }

        var pano = this.createConnectedPano(divname, options)
        if (!point) {
            point = Utils.destinationPoint(cp.lat, cp.lon, PanoGL.GLOBAL_CONFIG.RECORD_DISTANCE, cp.heading + 180)
        }

        pano.gotoLocation(point.lat, point.lon)

        return pano
    }

    PanoGL.prototype.getFrameLocation = function (n) {
        var otherPoints = this.dataParser.otherPoints
        var point
        var cp = this.dataParser.currentPoint
        var currentFrame = cp.frame
        var dirname = cp.dirname
        var tempFrame = currentFrame + n
        for (var i = 0; i < otherPoints.length; i++) {
            if (otherPoints[i].dirname === dirname && tempFrame === otherPoints[i].frame) {
                point = otherPoints[i]
            }
        }

        if (!point) {
            var ang = 0
            if (n < 0) {
                ang = 180
            }
            point = Utils.destinationPoint(cp.lat, cp.lon, PanoGL.GLOBAL_CONFIG.RECORD_DISTANCE * n, cp.heading + ang)
        }

        return point
    }

    PanoGL.prototype._connectedPanoSetup = function (evt) {
        var pano = evt.target
        pano.removeEvent(PanoGL.SETUP_COMLETE, this._connectedPanoSetup)
        pano.controller.setRotationY(this.mainCam.rotation.y)
    }

    /**
     * Panorama'ya başka bir panorama ekler ve bazı Eventları ortak kullanmalarını sağlar. (Örn. MOUSE_MOVE_ONGROUND)
     * @method assignPano
     * @memberof PanoGL
     * @param {PanoGL} pano
     * @return {void}
     * @public
     * @instance
     */
    PanoGL.prototype.assignPano = function (pano) {
        if (this.assignedPanos.indexOf(pano) === -1 && this !== pano) {
            this.assignedPanos.push(pano)
            pano.addEvent(PanoGL.MOUSE_MOVE_ON_GROUND, this, this._panoCursorOnAssigned)
        }
    }

    /**
     * Eklenmiş panorama event fonksiyonu.
     * @method _panoCursorOnAssigned
     * @memberof PanoGL
     * @param {Event} e
     * @return {void}
     * @private
     * @instance
     */
    PanoGL.prototype._panoCursorOnAssigned = function (e) {
        if (!this.dataParser || !this.dataParser.currentPoint) { return }

        this.setCursorPosition(e.lon, e.lat, e.alt, false, e.isWall, e.normal)
    }

    // Controls Start
    PanoGL.prototype.setCompassVisibility = function (b) {
        if (this._isCompassVisible !== b) {
            this._isCompassVisible = b
            if (b) {
                if (!this.compass) {
                    this.compass = new Compass(this.getRendererDom())
                }
            } else {
                if (this.compass) {
                    this.compass.dispose()
                    this.compass = null
                }
            }
        }
    }

    PanoGL.prototype.getCompassVisibility = function () {
        return this._isCompassVisible
    }

    PanoGL.prototype.setLabelVisibility = function (b) {
        if (this._isLabelVisible !== b) {
            this._isLabelVisible = b
            this.isDirty = true
        }
    }

    PanoGL.prototype.getLabelVisibility = function () {
        return this._isLabelVisible
    }

    PanoGL.prototype.setGroundLabelVisibility = function (b) {
        if (this._isGroundLabelVisible !== b) {
            this._isGroundLabelVisible = b
            if (this.groundLabelManager) {
                this.groundLabelManager.scene.visible = b
            }

            if (b) {
                this._setGroundLabels()
            }

            this.isDirty = true
        }
    }

    PanoGL.prototype.getGroundLabelVisibility = function () {
        return this._isGroundLabelVisible
    }

    PanoGL.prototype.setArrowVisibility = function (b) {
        if (this._isArrowVisible !== b) {
            if (this._autoUpdateSigns) {
                if (b) {
                    this._setArrows(this.dataParser)
                }
            }

            this.arrowManager.mouseDisabled = !b
            this._isArrowVisible = b
            this.isDirty = true
        }
    }

    PanoGL.prototype.getArrowVisibility = function () {
        return this._isArrowVisible
    }

    PanoGL.prototype.getLabelStyle = function () {
        return this.labelStyle
    }

    // TODO will be removed
    PanoGL.prototype.setTileZoomLevels = function (levels) {
        this._zoomLevels = levels
    }

    PanoGL.prototype.destroy = function () {
        var index = PanoGL._instances.indexOf(this)
        if (index > -1) {
            PanoGL._instances.splice(index, 1)
        }

        for (var i = 0; i < this.plugins.length; i++) {
            this.plugins[i].destroy()
        }

        document.removeEventListener('resize', this._resize)
        window.removeEventListener('resize', this._resize)
        Renderer.getInstance().removePanoGLInstance(this)

        var renderer = this.getRenderer()

        try {
            renderer.forceContextLoss()
        } catch (e) {

        }

        this.contentDV.innerHTML = ''
    }

    return PanoGL
})()

PanoGL.URLFactory = function () { }

Object.assign(PanoGL.URLFactory.prototype, {
    /**
     * Thumb için adres üreten method
     * @method getThumbPath
     * @memberof URLFactory
     * @public
     * @instance
     */
    getThumbPath: function (pano, data) {
        var dc = data.current
        var m = Utils.mergeStringForUrl
        var img
        if (dc.parent) {
            img = m(m(m(m(pano.ifolder, dc.parent), dc.dirname), '/' + pano.thumbSize.x + '/' + pano.thumbSize.y), dc.img)
        } else {
            img = m(m(m(pano.ifolder, dc.dirname), '/' + pano.thumbSize.x + '/' + pano.thumbSize.y), dc.img)
        }
        return img
    },

    /**
     * Büyük resim için URL üretir
     * @method getImagePath
     * @memberof URLFactory
     * @public
     * @instance
     */
    getImagePath: function (pano, data) {
        var m = Utils.mergeStringForUrl
        var img
        if (data.parent) {
            img = m(m(m(m(pano.ifolder, data.parent), data.dirname), '/4096/2048'), ('/' + data.img))
        } else {
            img = m(m(m(pano.ifolder, data.dirname), '/4096/2048'), data.img)
        }

        return img
    },

    /**
     * Request için URL üretir
     * @method getRequestURL
     * @memberof URLFactory
     * @instance
     * @public
     */
    getRequestURL: function (panogl, lon, lat) {
        var m = Utils.mergeStringForUrl
        var url = m(m(panogl.jsonConenction, lon), lat)
        return url
    }

})

export { PanoGL }
