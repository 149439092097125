import { Base } from './Base'
import { Utils } from './Utils'

DataParser.prototype = Object.create(Base.prototype)
DataParser.prototype.constructor = DataParser
DataParser.DATA_LOADED = 'dataLoaded'
function DataParser(panogl, requestURL, imagePath) {
    Base.apply(this, [])
    this.panogl = panogl
    this.currentPoint = null
    this.prevPoint = null
    this.otherPoints = []
    this.requestURL = requestURL
    this.imagePath = imagePath
    this.reset()
    this.complateJSONBind = this.onJSONDataComplated.bind(this)
}

// var complateJSONBind;
DataParser.prototype.sendPointRequest = function (lat, lon) {
    if (this._lastLat === lat && this._lastLon === lon) {
        return false
    }
    this._lastLat = lat
    this._lastLon = lon
    var url = this.panogl.getRequestURL(lon, lat)
    this.requestObject = createCORSRequest('GET', url)// this.requestURL + "lon/lat");//"?action=data&latlon="+lat+","+lon+"&radius=35&index=0");
    if (!this.requestObject) {
        this.reset()
        throw new Error('CORS not supported')
    }
    this.requestObject.onerror = this.onError
    this.requestObject.onload = this.complateJSONBind
    this.requestObject.send()
}

DataParser.prototype.onError = function (e) {
    this.reset()
}

DataParser.prototype.reset = function () {
    this._lastLat = null
    this._lastLon = null
}

DataParser.prototype.getCurrentConfig = function () {
    return this._config
}

DataParser.prototype.onJSONDataComplated = function (e) {
    let response = this.requestObject.response
    if (response) {
        var data
        try {
            data = JSON.parse(response)
        } catch (err) {
            console.log(err)
            return
        }

        if (!data.success) {
            return console.warn(data.message)
        }

        var p = data.pano
        this._config = data.config

        if (p) {
            this.prevPoint = this.currentPoint
            this.currentPoint = this.straightData(p[0])
            var i
            this.otherPoints.length = 0
            for (i = 1; i < p.length; i++) {
                var pd = this.straightData(p[i])
                this.otherPoints[i - 1] = pd
            }

            this.throwEvent({ type: DataParser.DATA_LOADED, data: { current: this.currentPoint, otherdata: this.otherPoints, rawData: data } })
        }
    }
}

DataParser.prototype.straightData = function (data) {
    data.gid = parseInt(data.gid)
    data.heading = parseFloat(data.heading)
    data.dist = parseFloat(data.dist)
    if (data.hasOwnProperty('frame')) {
        data.frame = parseFloat(data.frame) // parselFloat removes after numeric characters but before
    } else {
        data.frame = parseFloat(data.img.replace('pano_', '')) // parselFloat removes after numeric characters but before
    }
    data.pitch = parseFloat(data.pitch) // parselFloat removes after numeric characters but before
    data.roll = parseFloat(data.roll)
    data.altitude = parseFloat(data.altitude)
    var a = data.latlon.split(' ')
    data.lat = parseFloat(a[0])
    data.lon = parseFloat(a[1])

    if (this.currentPoint) {
        var az = ((Utils.bearing(this.currentPoint.lat, this.currentPoint.lon, data.lat, data.lon))) % 360
        data.azimuth = (360 - az) % 360
        data.heversine = Utils.heversine(this.currentPoint.lat, data.lat, this.currentPoint.lon, data.lon)
    }

    return data
}

function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest()
    if ('withCredentials' in xhr) {
        xhr.open(method, url, true)
    } else if (typeof XDomainRequest !== 'undefined') {
        xhr = new XDomainRequest()
        xhr.open(method, url, true)
    } else {
        xhr = null
    }
    return xhr
}

export { DataParser }
