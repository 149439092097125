
import {Arrow} from './Arrow'
import {Base} from './Base'
import {TO_RAD, Utils} from './Utils'
/**
 * Navigasyon oklarının ayarlandığı class
 * @class ArrowManager
 * @extends THREE.Object3D
 * @param {PanoGL}
 * @param {THREE.Scene}
 * @param {THREE.PerspectiveCamera}
*/

ArrowManager.prototype = Object.create(THREE.Object3D.prototype)
var prot = Object.create(Base.prototype)
for (var str in prot) { ArrowManager.prototype[str] = prot[str] }
ArrowManager.prototype.constructor = ArrowManager
ArrowManager.ARROW_DISTANCE = 100
function ArrowManager (panogl, renderScene, renderCam) {
    this._aliveArrows = []
    this._deadArrows = []
    this.panogl = panogl
    this.camera = panogl.mainCam
    this.renderScene = renderScene
    this.renderCam = renderCam
    this.nextRotY = 0
    this.nextRotX = 0
    this.isDirty = true

    THREE.Object3D.apply(this, [])

    this.position.z = -ArrowManager.ARROW_DISTANCE
    this.position.y = -50
}

/**
 * @memberof ArrowManager
 * @method init
 * @public
 * @instance
*/
ArrowManager.prototype.init = function () {
    this.prepareArrow()
}

/**
 * Okların imajları dolduğunda sahneyi yenilemesi için kullanılan event fonksiyonu
 * @memberof ArrowManager
 * @method onLoadImage
 * @public
 * @instance
*/
ArrowManager.prototype.onLoadImage = function () {
    this.isDirty = true
}

/**
 * update methodu. Kameraya göre, X ve Y rotasyonu uygulanır.
 * @memberof ArrowManager
 * @method update
 * @public
 * @instance
*/
ArrowManager.prototype.updateEvent = {type: 'ArrowUpdateEvent'}
ArrowManager.prototype.update = function () {
    this.rotation.set(-this.camera.rotation.x, -this.camera.rotation.y, 0)
}

/**
 * Parametrelere  göre Ok eklenir.
 * @memberof ArrowManager
 * @method addArrows
 * @param {Array} ar  Ok dataları
 * @public
 * @instance
*/
ArrowManager.prototype.arrowAdded = {type: 'ArrowAddedManager'}
ArrowManager.prototype.addArrows = function (ar) {
    for (var i = 0; i < ar.length; i++) {
        var arrow = this.getArrow()
        this.add(arrow)
        arrow.setData(ar[i])
        arrow.setAngle(ar[i].azimuth * TO_RAD)
    }

    this.arrowAdded.data = ar
    this.throwEvent(this.arrowAdded)
}

/**
 * Ok tıklama event callback fonksiyonu.
 * @memberof ArrowManager
 * @method onClickArrow
 * @param {Event} e
 * @public
 * @instance
*/
ArrowManager.prototype.onClickArrow = function (e) {
    var d = e.target.data
    this.throwEvent({type: 'arrowClicked', target: e.target, data: d})
}

/**
 * Eğer daha önce oluşturulmuş, kullanılmayan ok varsa, onu döner, yoksa yeniden oluşturur.
 * @memberof ArrowManager
 * @method getArrow
 * @public
 * @return {Arrow}
 * @instance
*/
ArrowManager.prototype.getArrow = function () {
    var ar
    var deadArrows = this._deadArrows
    var aliveArrows = this._aliveArrows
    if (deadArrows.length) {
        ar = deadArrows.splice(0, 1)[0]
        aliveArrows.push(ar)
        return ar
    }
    ar = this.createArrow()
    aliveArrows.push(ar)
    return ar
}

/**
 * Ok oluşturur.
 * @memberof ArrowManager
 * @method createArrow
 * @public
 * @return {Arrow}
 * @instance
*/
ArrowManager.prototype.createArrow = function () {
    var a = new Arrow(this.panogl, this.renderScene, this.renderCam)
    a.addEvent('click', this, this.onClickArrow)
    return a
}

/**
 * 7 tane Ok oluştururup listeye ekler.
 * @memberof ArrowManager
 * @method prepareArrow
 * @public
 * @instance
*/
ArrowManager.prototype.prepareArrow = function () {
    for (var i = 0; i < 7; i++) {
        this._deadArrows.push(this.createArrow())
    }
}

/**
 * Bütün okları sahneden silip, pool edilmek üzere listeye ekler.
 * @memberof ArrowManager
 * @method removeAll
 * @public
 * @instance
*/
ArrowManager.prototype._cleanedEvent = {type: 'cleanedEvent'}
ArrowManager.prototype.removeAll = function () {
    while (this._aliveArrows.length) {
        var a = this._aliveArrows[0]
        if (a.parent) {
            this.remove(a)
        }
        this._deadArrows.push(this._aliveArrows.splice(0, 1)[0])
    }

    this.throwEvent(this._cleanedEvent)
}

/**
 * Okları belli algoritmalara göre eler.
 * @memberof ArrowManager
 * @method filterArrow
 * @param {Object} cr Bulunulan nokta datası
 * @param {Array} pts Noktalar arrayı
 * @public
 * @instance
*/
ArrowManager.filterArrow = function (cr, pts) {
    var ANGEL_DIFF = 80
    var ARROW_BETWEEN = 20
    var MIN_DISTANCE = 20
    var sarrow = []
    var dn = {}
    var i
    for (i = 0; i < pts.length; i++) {
        if (!dn[pts[i].dirname]) {
            dn[pts[i].dirname] = []
        }
        dn[pts[i].dirname].push(pts[i])
    }

    var a = dn[cr.dirname]

    if (a) {
        var f = 5000000
        var l = -5000000
        var fd = null
        var bd = null
        for (i = 0; i < a.length; i++) {
            if (a[i].frame > cr.frame) {
                if (a[i].frame < f) {
                    fd = a[i]
                    f = fd.frame
                }
            } else if (a[i].frame < cr.frame) {
                if (a[i].frame > l) {
                    bd = a[i]
                    l = bd.frame
                }
            }
        }
        if (fd) {
            fd.isValid = true
            sarrow.push(fd)
        }
        if (bd) {
            bd.isValid = true
            sarrow.push(bd)
        }
    }

    delete dn[cr.dirname]

    var ars = []
    for (var str in dn) {
        a = dn[str]
        a = a.sort(Utils.sortDist)
        var a1 = a[0]
        ars.push(a1)
        if (a.length > 1) {
            var a2 = a[1]
            if (a2.dist < ARROW_BETWEEN) {
                var different = Utils.angleDifference(a1.azimuth, a2.azimuth)
                if (different > ANGEL_DIFF) {
                    ars.push(a2)
                }
            }
        }
    }

    var filterLewel = 30
    ars = ars.sort(Utils.sortDist)

    for (i = 0; i < ars.length; i++) {
        a = ars[i]
        var isFar = false
        for (var j = 0; j < sarrow.length; j++) {
            var b = sarrow[j]
            if (a !== b && !isFar) {
                var degree = Utils.angleDifference(a.azimuth, b.azimuth)
                if (degree < filterLewel) {
                    isFar = true
                }
            }
        }

        if (!isFar)sarrow.push(a)

        isFar = false
    }

    var temp = []
    for (i = 0; i < sarrow.length; i++) {
        if (sarrow[i].heversine < MIN_DISTANCE || fd === sarrow[i] || bd === sarrow[i]) {
            temp.push(sarrow[i])
        }
    }

    return temp
}

export {ArrowManager}
