import { AnkaPromise } from './AnkaPromise'

let AnkaFetch

if (typeof window.fetch === 'function') {
    AnkaFetch = fetch.bind()

    class ResponseObject {
        constructor(xhttp) {
            this.json = function () {
                try {
                    return JSON.parse(xhttp.response)
                } catch (error) {
                    return error
                }
            }
        }
    }

    AnkaFetch = function (url, options) {
        return new AnkaPromise((resolve, reject) => {
            try {
                var method = options && options.method ? options.method : 'get'
                var xhttp = new XMLHttpRequest()
                xhttp.addEventListener('load', function () {
                    if (xhttp.readyState === 4) {
                        var response = new ResponseObject(xhttp)
                        if (response instanceof Error) {
                            reject(response)
                        } else {
                            resolve(response)
                        }
                    }
                })
                xhttp.open(method, url, true)
                xhttp.send()
            } catch (error) {
                reject(error)
            }
        })
    }
}

export { AnkaFetch }
