
import { PanoGL } from './PanoGL'
import { CursorManager } from './CursorManager'

var MeshMousePicker = (function () {
    MeshMousePicker._meshes = []
    MeshMousePicker.CLICK = 'click'
    MeshMousePicker.MOUSE_DOWN = 'onMouseDown'
    MeshMousePicker.MOUSE_MOVE = 'mousemove'
    // MeshMousePicker.LINE_TOUCH_TICKNESS  = 1;
    // var _clickableMeshesArray = [];
    // var meshClickCamera;
    // var mouseClickeDom;
    var visiblesToRays = []
    var _intersectsObjects
    var _raycasterForMouse = new THREE.Raycaster()
    _raycasterForMouse.linePrecision = PanoGL.GLOBAL_CONFIG.LINE_TOUCH_TICKNESS

    var _mousePos = new THREE.Vector3()
    var _mcselected
    var allowMMPickerInitialze = false
    function MeshMousePicker() {
        if (!allowMMPickerInitialze) {
            throw new Error('MeshMousePicker class is a singleton class. use static getInstance() method instead.')
        } else {
            allowMMPickerInitialze = false
        }
    }

    var mmpinstance
    MeshMousePicker.getInstance = function () {
        if (!mmpinstance) {
            allowMMPickerInitialze = true
            mmpinstance = new MeshMousePicker()
        }
        return mmpinstance
    }

    var panogls = {}
    var cameras = {}
    MeshMousePicker.prototype.registerPanoGL = function (panogl) {
        var dvn = panogl.getRendererDom()
        if (!panogls[panogl.uid]) {
            panogls[panogl.uid] = {}
        }

        dvn.addEventListener('mousedown', mouseDownFunction)
        dvn.addEventListener('mouseup', mouseUpFunction)
        dvn.addEventListener(MeshMousePicker.MOUSE_MOVE, mouseMoveFunction)
    }

    THREE.Object3D.prototype.setClickable = function (b, panogl, scene, cam) {
        _raycasterForMouse.linePrecision = PanoGL.GLOBAL_CONFIG.LINE_TOUCH_TICKNESS
        if (b) {

            if (!panogls[panogl.uid]) {
                MeshMousePicker.getInstance().registerPanoGL(panogl)
                // throw new Error(panogl.uid + " PanoGL örneği, sahneye mouse işlemleri için eklenmemiş.");
            }

            var camID = cam.uuid + panogl.uid
            if (!panogls[panogl.uid][camID]) {
                panogls[panogl.uid][camID] = []
            }

            let meshCamArray = panogls[panogl.uid][camID]
            if (meshCamArray.indexOf(this) < 0) {
                cameras[camID] = cam
                meshCamArray.push(this)// {src:this, camera:cam});
                MeshMousePicker._meshes.push(this)
                this.userData.cmPanoGLUiId = panogl.uid
                this.userData.camID = camID
            }


        } else {
            if (this.userData.cmPanoGLUiId) {
                var ar = panogls[this.userData.cmPanoGLUiId][this.userData.camID]
                var i = ar.indexOf(this)
                if (i > -1) ar.splice(i, 1)

                i = MeshMousePicker._meshes.indexOf(this)
                if (i > -1) MeshMousePicker._meshes.splice(i, 1)
            }
        }
    }

    function mouseDownFunction(e) {
        if (e.button === 0) {
            this.downOffsetX = e.offsetX
            this.downOffsetY = e.offsetY
            _mcselected = checkIntersection(e.target, e.offsetX, e.offsetY)

            if (_mcselected) {
                _mcselected.object.throwEvent({ type: MeshMousePicker.MOUSE_DOWN, target: _mcselected.object, intersectPoint: _mcselected.point })
            }
            // _mcselected.object.throwEvent({type:MeshMousePicker.CLICK, target:temp.object});
        }
    }

    function mouseUpFunction(e) {
        if (e.button === 0) {
            var xDif = Math.abs(this.downOffsetX - e.offsetX)
            var yDif = Math.abs(this.downOffsetY - e.offsetY)
            if (xDif < 2 && yDif < 2) {
                var temp = checkIntersection(e.target, e.offsetX, e.offsetY)


                if (temp && _mcselected) {
                    if (temp.object === _mcselected.object) {
                        temp.object.throwEvent({ type: MeshMousePicker.CLICK, target: temp.object, point: Object.assign({}, temp.point) })
                    }
                }
            }
            _mcselected = temp = null
        }
    }

    function mouseMoveFunction(e) {
        var temp = checkIntersection(e.target, e.offsetX, e.offsetY)
        if (temp) {
            CursorManager.setCursor('pointer')
            PanoGL.isPointerOnClickableObject = true
            temp.object.throwEvent({ type: MeshMousePicker.MOUSE_MOVE, target: temp.object, point: Object.assign({}, temp.point) })
        } else {
            CursorManager.setCursor('auto')
            PanoGL.isPointerOnClickableObject = false
        }
    }

    function checkIntersection(cnv, mx, my) {
        visiblesToRays.length = 0

        var canvasID = cnv.id
        for (var str in cameras) {
            var _tempCam = cameras[str]

            if (!panogls[canvasID]) continue

            var clickableMeshes = panogls[canvasID][str]

            if (!clickableMeshes || clickableMeshes.length < 1) continue

            _mousePos.x = (mx / cnv.width) * 2 - 1
            _mousePos.y = -(my / cnv.height) * 2 + 1
            _raycasterForMouse.setFromCamera(_mousePos, _tempCam)

            var _temp = []
            var cm
            var i
            for (i = 0; i < clickableMeshes.length; i++) {
                cm = clickableMeshes[i]

                var isParentAllowed = checkParent(cm)

                if (cm.visible && isParentAllowed) {
                    _temp.push(cm)
                }
            }

            _intersectsObjects = _raycasterForMouse.intersectObjects(_temp, false)

            for (i = 0; i < _intersectsObjects.length; i++) {
                visiblesToRays.push(_intersectsObjects[i])
            }

            if (visiblesToRays.length > 0) {
                // totalTouches.push(visiblesToRays[0]);

                if (visiblesToRays.length > 1) {
                    for (i = 0; i < visiblesToRays.length; i++) {
                        var obj = visiblesToRays[i].object
                        var pri = parseInt(obj.userData.clickPriority)
                        visiblesToRays[i].clickPriority = isNaN(pri) ? 1 : pri
                    }

                    var selected = visiblesToRays[0]

                    for (i = 0; i < visiblesToRays.length; i++) {
                        if (selected.clickPriority < visiblesToRays[i].clickPriority) {
                            selected = visiblesToRays[i]
                        }
                    }

                    return selected
                }
                return visiblesToRays[0]
            }
        }

        return null
    }

    function checkParent(cm) {
        if (!cm.visible || cm.mouseDisabled) { return false }

        if (cm.parent && !cm.mouseDisabled) {
            var f = checkParent(cm.parent)

            if (!f) {
                return false
            }
        } else if (!cm.parent && cm instanceof THREE.Scene) {
            if (!cm.mouseDisabled) {
                return true
            } else {
                return false
            }
        }

        return true
    }

    return MeshMousePicker
})()

export { MeshMousePicker }
