import {Base} from './Base'
import {AssetManager} from './AssetManager'

Compass.prototype = Object.create(Base.prototype)
Compass.prototype.constructor = Compass
function Compass (parentDom) {
    Base.apply(this, [])
    this.parentDom = parentDom
    this.init()
}

Compass.prototype.init = function () {
    var comSize = 80
    var back = this.back = document.createElement('div')

    var s = back.style
    s.position = 'absolute'
    s.zIndex = '10'
    s.backgroundColor = 'tranparent'
    s.backgroundImage = "url('" + AssetManager.getPathAt(5) + "')"
    s.backgroundSize = '100% 100%'
    s.width = comSize + 'px'
    s.height = comSize + 'px'
    s.top = '20px'
    s.right = '5px'
    s.pointerEvents = 'none'

    this.needle = document.createElement('div')

    s = this.needle.style
    s.position = 'absolute'
    s.zIndex = '10'
    s.backgroundColor = 'tranparent'
    s.backgroundImage = "url('" + AssetManager.getPathAt(6) + "')"
    s.width = '12px'
    s.backgroundSize = '100% 100%'
    s.height = '60px'
    s.top = '11.5px'
    s.right = ((comSize - parseFloat(s.width)) / 2) + 'px'
    s.pointerEvents = 'none'

    setDegreToStyle(s, 0)

    this.parentDom.parentElement.appendChild(back)
    back.appendChild(this.needle)
}

Compass.prototype.setDegree = function (degree) {
    this.currentDegre = degree = 360 - degree
    var s = this.needle.style
    setDegreToStyle(s, degree)
}

Compass.prototype.dispose = function () {
    if (this.back && this.back.parentElement) {
        this.back.parentElement.removeChild(this.back)
        this.back = null
    }
}

function setDegreToStyle (style, degree) {
    style.webkitTransform = 'rotate(' + degree + 'deg)'
    style.MozTransform = 'rotate(' + degree + 'deg)'
    style.msTransform = 'rotate(' + degree + 'deg)'
    style.transform = 'rotate(' + degree + 'deg)'
    style.OTransform = 'rotate(' + degree + 'deg)'
}

export {Compass}
