
// import {Tile} from "./Tile";

var AssetManager = (function () {
    var instance
    var allowInitialize = false
    /**
     * AssetManager uygulama genelinde; network işlemleri, network üzerinden çekilen datanın cache edilmesi işlemlerini yapar.
     * AssetManager öğesi manual olarak oluşturulamaz. Static method getInstance kullanılmalıdır.
     * @class AssetManager
     * @memberof AssetManager
    */
    function AssetManager () {
        if (!allowInitialize) {
            throw new Error('AssetManager cannot be initialized manually. Use AssetManager.getInstance()')
        } else {
            allowInitialize = false
            this.imageData = {}
        }
    }

    /**
     * singleton
     * @method getInstance Static
     * @return {AssetManager}
     * @memberof AssetManager
    */
    AssetManager.getInstance = function () {
        if (!instance) {
            allowInitialize = true
            instance = new AssetManager()
        }

        return instance
    }

    /**
     * @method getImage
     * @param {String} url Datanın çekileceği URL.
     * @param {function} completeCallBack data yüklendiğinde çalıştırılacak callBack fonksiyonu. @class AssetManager (Zorunlu değil)
     * @param {function} progressCallBack data yüklenme oranını döner. (Zorunlu değil)
     * @param {function} errorCallBack hata anında geri dönecek callBack fonksiyonu. (Zorunlu değil)
     * @memberof AssetManager
    */
    AssetManager.prototype.getImage = function (url, complete, progress, error) {
        if (this.imageData[url] !== undefined) {

        }
    }

    /**
     * @method GetAssetNoCache Cache etmeden load eder.
     * @param {String} url Datanın çekileceği URL.
     * @param {function} completeCallBack data yüklendiğinde çalıştırılacak callBack fonksiyonu. @class AssetManager (Zorunlu değil)
     * @param {function} progressCallBack data yüklenme oranını döner. (Zorunlu değil)
     * @param {function} errorCallBack hata anında geri dönecek callBack fonksiyonu. (Zorunlu değil)
     * @return {THREE.Texture}
     * @memberof AssetManager
    */
    AssetManager.prototype.GetAssetNoCache = function (url, complete, progress, error) {
        var tloader = new THREE.TextureLoader()
        var texture = tloader.load(url, function (data) {
            if (complete) {
                complete(data)
            }
        },
        function (val) {
            if (progress) {
                progress(val)
            }
        },
        function (err) {
            if (error) {
                error(err)
            }
        }
        )
        return texture
    }

    AssetManager.prototype.GetMaterialWithTexture = function (url, ops, callBack) {
        var ud = this

        if (!ud.assetSupplier) {
            ud.assetSupplier = {}
            ud.assetSuplierEvents = {}
        }

        var cbtime = 100

        if (ud.assetSupplier[url]) {
            if (callBack) {
                var material = ud.assetSupplier[url]
                var image = material.map.image
                if (image) {
                    callBack(ud.assetSupplier[url])
                    return
                }
            }
        }

        var meshMatera

        if (!ud.assetSuplierEvents[url]) {
            ud.assetSuplierEvents[url] = []
        } else {
            ud.assetSuplierEvents[url].push(callBack)
            return
        }

        var am = AssetManager.getInstance()

        var t = am.GetAssetNoCache(url, function (e) {
            if (callBack) {
                setTimeout(function () {
                    if (ops === undefined)ops = {}
                    ops.map = t
                    meshMatera = new THREE.MeshBasicMaterial(ops)
                    meshMatera.name = 'CreatedMesh'
                    ud.assetSupplier[url] = meshMatera

                    var ar = ud.assetSuplierEvents[url]
                    ar.splice(0, 0, callBack)
                    for (var i = 0; i < ar.length; i++) {
                        ar[i](meshMatera)
                    }

                    delete ud.assetSuplierEvents[url]
                }, cbtime)
            }
        },
        undefined,
        function (e) {
            console.error(e)
            if (callBack)callBack(null, e)
        }
        )
    }

    let baseHref = ''
    AssetManager.setBaseHref = function (basePath) {
        baseHref = basePath
    }

    AssetManager.getEmptyPanoPath = function () {
        return baseHref + 'img/bos.png'
    }

    let paths = [
        'img/bos.png',
        'img/gm.png',
        'img/gm_close.png',
        'img/drawing_tv.png',
        'img/hinter.png',
        'img/compass-back.png',
        'img/needle.png',
        'img/tarrow.png' // 7
    ]
    AssetManager.getPathAt = function (index) {
        return baseHref + paths[index]
    }

    return AssetManager
})()

export {AssetManager}
