import { Base } from './Base'
import { PanoGL } from './PanoGL'
import { CursorManager } from './CursorManager'
import { TO_RAD, TO_DEG } from './Utils'

const noSelectOnPanoClass = 'noselect-on-pano-move'

PanoromicController.prototype = Object.create(Base.prototype)
PanoromicController.prototype.constructor = PanoromicController
function PanoromicController(panogl, target, camera, panoDom) {
    this.panogl = panogl
    this.camera = camera
    this.target = target
    this.baseDom = panoDom
    this.isDirty = true
    this.downX = 0
    this.downY = 0
    this.currentDownX = 0
    this.currentDownY = 0
    this.nextFov = camera.fov

    this.setupSelectionDisableCSS()

    var baseDom = this.baseDom
    if (!baseDom.attachEvent) {
        if (!PanoGL.isMobile) {
            this.mouseDownEvent = this.mouseDownEvent.bind(this)
            this.mouseUpEvent = this.mouseUpEvent.bind(this)
            this.mouseMoveEvent = this.mouseMoveEvent.bind(this)
            this.mouseLeaveEvent = this.mouseLeaveEvent.bind(this)
            this.mouseWheelEvent = this.mouseWheelEvent.bind(this)
            baseDom.addEventListener('mousedown', this.mouseDownEvent)
            baseDom.addEventListener('wheel', this.mouseWheelEvent)
            baseDom.addEventListener('mouseleave', this.mouseLeaveEvent)
        } else {
            this.mouseDownEvent = this.touchDownEvent.bind(this)
            this.mouseUpEvent = this.touchUpEvent.bind(this)
            this.mouseMoveEvent = this.touchMoveEvent.bind(this)
            baseDom.addEventListener('touchstart', this.mouseDownEvent)
        }
    } else {
        console.log('ie için yazılacak')
    }
}

PanoromicController.MouseWheelWay = 1;

PanoromicController.prototype.setupSelectionDisableCSS = function () {
    if (!document.getElementById('noselectOnPanoMove')) {
        let cssStyle = document.createElement('style')
        cssStyle.id = 'noselectOnPanoMove'
        cssStyle.innerHTML = '' +
            '.noselect-on-pano-move * {' +
            '-webkit-touch-callout: none;' +
            '-webkit-user-select: none;' +
            '-khtml-user-select: none;' +
            '-moz-user-select: none;' +
            '-ms-user-select: none;' +
            'user-select: none;' +
            '}'
        document.head.appendChild(cssStyle)
    }
}

PanoromicController.prototype.touchDownEvent = function (e) {
    var rect = this.baseDom.getBoundingClientRect()
    var touch = e.touches[0]
    var clientX = touch.clientX - rect.left
    var clientY = touch.clientY - rect.top

    this.currentIdentifier = touch.identifier
    this.downStartX = this.currentDownX = clientX
    this.downStartY = this.currentDownY = clientY

    document.addEventListener('touchend', this.mouseUpEvent)
    this.baseDom.addEventListener('touchmove', this.mouseMoveEvent)

    this.isDown = true
    this.isDirty = true
}

PanoromicController.prototype.touchMoveEvent = function (e) {
    var rect = this.baseDom.getBoundingClientRect()
    var touch = e.touches[0]
    var clientX = touch.clientX - rect.left
    var clientY = touch.clientY - rect.top

    this.currentDownX = clientX
    this.currentDownY = clientY

    if (this.downStartX === clientX && this.downStartY === clientY) {
        return
    }

    this.isDirty = true
    this.isMoving = true
}

PanoromicController.prototype.touchUpEvent = function (e) {
    document.removeEventListener('touchend', this.touchUpEvent)
    this.baseDom.removeEventListener('touchmove', this.mouseMoveEvent)
    if (this.isDown) {
        this.stopDragging()
    }
}

PanoromicController.prototype.mouseLeaveEvent = function (event) {
    if (this.isDown) {
        this.stopDragging()
    }
}

PanoromicController.prototype.mouseWheelEvent = function (event) {
    var delta = 0
    if (event.deltaY > 0) {
        delta = 3
    } else {
        delta = -3
    }

    delta *= PanoromicController.MouseWheelWay;
    this.addFov(-delta)
}

PanoromicController.prototype.mouseDownEvent = function (event) {
    if (this.controlDisabled) {
        return
    }

    if (event.button === 0) {
        this.isDown = true

        if (!document.body.classList.contains(noSelectOnPanoClass)) document.body.classList.add(noSelectOnPanoClass)

        this.downStartX = this.currentDownX = event.clientX
        this.downStartY = this.currentDownY = event.clientY
        document.addEventListener('mouseup', this.mouseUpEvent)
        document.addEventListener('mousemove', this.mouseMoveEvent)
        this.isDirty = true
    }
}

PanoromicController.prototype.mouseUpEvent = function (event) {
    if (event.button === 0) {
        this.stopDragging()
    }
}

PanoromicController.prototype.stopDragging = function () {
    if (document.body.classList.contains(noSelectOnPanoClass)) document.body.classList.remove(noSelectOnPanoClass)

    this.downStartX = NaN
    this.downStartY = NaN
    document.removeEventListener('mouseup', this.mouseUpEvent)
    document.removeEventListener('mousemove', this.mouseMoveEvent)
    CursorManager.setCursor('default', 0)
    this.isDown = false
}

PanoromicController.prototype.mouseMoveEvent = function (event) {
    this.currentDownX = event.clientX
    this.currentDownY = event.clientY
    if (this.downStartX === this.currentDownX && this.downStartY === this.currentDownY) {
        return
    }
    CursorManager.setCursor('all-scroll', 0)
    this.isDirty = true
    this.isMoving = true
}

PanoromicController.prototype.nextRotX = 0
PanoromicController.minVFov = -1
PanoromicController.maxVFov = 1.8
PanoromicController.MOVING_SPEED = 1

var _viewAngleEvent = { type: 'onViewAngleChange', rotationY: 0 }
PanoromicController.prototype.update = function () {
    var isViewChanged = false

    if (this.isDown && this.isMoving) {
        if (this.currentDownX !== this.downStartX || this.currentDownY !== this.downStartY) {
            var nextXRotY = this.currentDownX - this.downStartX
            var nextYRotX = this.currentDownY - this.downStartY
            var xFov = (this.camera.fov * this.panogl.aspRatio)
            var radY = (xFov * (nextXRotY / this.panogl.stageWidth)) * TO_RAD
            var radX = (this.camera.fov * (nextYRotX / this.panogl.stageHeight)) * TO_RAD

            var newY = this.camera.rotation.y + (radY * PanoromicController.MOVING_SPEED)
            var newX = this.camera.rotation.x + (radX * PanoromicController.MOVING_SPEED)

            if (newX > PanoromicController.maxVFov) {
                newX = PanoromicController.maxVFov
            } else if (newX < PanoromicController.minVFov) {
                newX = PanoromicController.minVFov
            }

            this.camera.rotation.set(newX, newY, 0, 'YXZ')
            this.downStartX = this.currentDownX
            this.downStartY = this.currentDownY
            isViewChanged = true
        }
    }

    if (this.isDirty) {
        this.camera.fov = this.nextFov
        this.camera.updateProjectionMatrix()
        isViewChanged = true
    }

    if (isViewChanged) {
        this.onCameraViewChange()
    }

    this.isDirty = false
}

PanoromicController.prototype.disable = function (b) {
    this.controlDisabled = b
    if (b) {
        this.stopDragging()
    }
}

PanoromicController.prototype.onCameraViewChange = function () {
    _viewAngleEvent.rotationY = this.camera.rotation.y * TO_DEG
    _viewAngleEvent.rotationX = this.camera.rotation.x * TO_DEG
    _viewAngleEvent.fov = this.getCurrentFovRatio()
    this.throwEvent(_viewAngleEvent)
}

PanoromicController.prototype.getCurrentFovRatio = function () {
    return this.camera.fov / PanoromicController.maxCamFov
}

PanoromicController.prototype.getRotationYDeg = function () {
    return this.camera.rotation.y * TO_DEG
}

// PanoromicController.prototype.nextRotY = 0
// PanoromicController.prototype.addRotationToY = function (val) {
//     this.nextRotY += val * (this.camera.fov / 75)
// }

PanoromicController.maxCamFov = 90
PanoromicController.minCamFov = 20
PanoromicController.prototype.nextFov = 0
PanoromicController.prototype.addFov = function (val) {
    this.nextFov += val
    if (this.nextFov > PanoromicController.maxCamFov) {
        this.nextFov = PanoromicController.maxCamFov
    } else if (this.nextFov < PanoromicController.minCamFov) {
        this.nextFov = PanoromicController.minCamFov
    }
    this.isDirty = true
}

PanoromicController.prototype.lookAt = function (v3) {
    this.camera.lookAt(v3)
    // this.nextRotX = this.camera.rotation.x
    // this.nextRotY = this.camera.rotation.y
    this.isDirty = true
}

PanoromicController.prototype.setRotationY = function (rotY) {
    this.camera.rotation.y = rotY// lookAt(v3);
    // this.nextRotX = this.camera.rotation.x
    // this.nextRotY = this.camera.rotation.y
    this.isDirty = true
}

PanoromicController.prototype.setRotationYX = function (rotY, rotX) {
    // this.camera.rotation.y = rotY
    // this.camera.rotation.x = rotX
    this.camera.rotation.set(rotX, rotY, 0, 'YXZ')

    this.isDirty = true
}


PanoromicController.prototype.getRotationYXInRadian = function () {
    return {
        x: this.camera.rotation.x,
        y: this.camera.rotation.y
    }
}




export { PanoromicController }
