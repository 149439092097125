import { Base } from './Base'
import { AssetManager } from './AssetManager'
import { TO_RAD } from './Utils'
PanoSphere.prototype = Object.create(THREE.Object3D.prototype)
PanoSphere.prototype.constructor = PanoSphere
PanoSphere.IMAGE_LOADED = 'imageLoaded'
for (var str in Base.prototype) { PanoSphere.prototype[str] = Base.prototype[str] }

function PanoSphere(panoGL) {
    this.panoGL = panoGL
    this.camera = panoGL.mainCam
    this.isDirty = true
    this.cleanCount = 0
    THREE.Object3D.apply(this, [])

    this.onLoadImage = this.onLoadImage.bind(this)
    var am = AssetManager.getInstance()

    var image = am.GetAssetNoCache(AssetManager.getEmptyPanoPath(), function () {
        panoGL.isDirty = true
    })

    image.wrapS = image.wrapT = THREE.RepeatWrapping
    image.repeat.set(16, 8)
    image.minFilter = THREE.LinearFilter
    image.magFilter = THREE.NearestFilter

    this._colorMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 })
    this.material = new THREE.MeshLambertMaterial({ map: image, emissive: 0x000000 })
    var geometry = new THREE.SphereBufferGeometry(1000, 1000, 1000)

    this.panoSphere = new THREE.Mesh(geometry, this.material)

    this.panoSphere.scale.set(-1, 1, 1)

    this.cover = new THREE.Object3D()
    this.cover.add(this.panoSphere)
    this.add(this.cover)
}

PanoSphere.prototype.onLoadImage = function (img) {
    if (this.panoGL.filterImage !== undefined) {
        img = this.panoGL.filterImage(img)
    }

    let material = this.material
    material.map.image = img
    material.map.repeat.set(1, 1)
    material.map.needsUpdate = true
    material.map.generateMipmaps = false
    material.map.minFilter = THREE.LinearFilter
    material.map.magFilter = THREE.NearestFilter
    this.panoSphere.material = material
    this.throwEvent({ type: PanoSphere.IMAGE_LOADED, data: null })
    this.isDirty = true
    this.panoGL.isDirty = true
}

PanoSphere.prototype.setThumbImage = function (path) {
    if (!this.loader) {
        this.loader = new THREE.ImageLoader()
        this.loader.crossOrigin = ''
    }

    // path = '/sphere.png'
    this._lastPath = path
    this.loader.load(path, this.onLoadImage)
    this.isDirty = true
}

PanoSphere.prototype.disposeData = function () {
    if (this.exImage) this.exImage.dispose()

    if (this.exmat) this.exmat.dispose()
}

PanoSphere.prototype.setColor = function () {
    this._isColor = true
    this.panoSphere.material = this._colorMaterial
}

PanoSphere.prototype.showImage = function (url) {
    this._isColor = false
    this.panoSphere.material = this.material
    if (typeof url === 'string') {
        if (this._lastPath !== url) {
            this.setThumbImage(url)
        }
    }
}

PanoSphere.prototype.isColor = function () {
    return this._isColor
}

PanoSphere.prototype.setOriantation = function (heading, pitch, roll) {
    var newHeading = -heading - (90 * TO_RAD)
    var rotHeading = -90 * TO_RAD
    this.cover.rotation.set(pitch, -rotHeading, -roll, 'YXZ')
    this.panoSphere.rotation.set(0, rotHeading, 0, 'YXZ')
    this.rotation.set(0, newHeading, 0)
    this.isDirty = true
}

PanoSphere.prototype.update = function () {
    this.cleanCount--
    if (this.cleanCount < 0) {
        this.isDirty = false
    }
}

export { PanoSphere }
