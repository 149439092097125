/* eslint-disable */
function getWorkerByID(workerID, pool) {
    return Array.isArray(pool[workerID]) && pool[workerID].length > 0 && pool[workerID].splice(0, 1)[0]
}

function putIn(worker, workerID, poolObject) {
    if (!Array.isArray(poolObject[workerID])) {
        poolObject[workerID] = []
    }
    poolObject[workerID].push(worker)
}

function remove(worker, workerID, poolObject) {
    var poolList = poolObject[workerID]
    if (poolList) {
        var workerIndex = poolList.indexOf(worker)
        if (workerIndex > -1) {
            let worker = poolList.splice(workerIndex, 1)[0]
            if (!Array.isArray(_pool[workerID])) {
                _pool[workerID] = []
            }
            _pool[workerID].push(worker)
        }
    }
}

var _pool = {}
var _activePools = {}
function WorkerManager() { }

WorkerManager.getBlobWorker = function (workerID, blobParams) {
    if (typeof workerID !== 'string') {
        throw new Error('need to pass a worker ID')
    }

    if (!Array.isArray(blobParams)) {
        throw new Error('blob parameter should be an array')
    } else if (blobParams.length === 0) {
        throw new Error('Blog parameter cannot be empty')
    }

    var deadCount = 0
    if (Array.isArray(_pool[workerID])) {
        deadCount = _pool[workerID].length
    }

    var worker = getWorkerByID(workerID, _pool)
    if (worker instanceof Worker) {
        putIn(worker, workerID, _activePools)
        return worker
    }

    var blob
    if (typeof window.MSBlobBuilder === 'function') {
        var msBuilder = new MSBlobBuilder()
        for (let i = 0; i < blobParams.length; i++) {
            msBuilder.append(blobParams[i])
        }
        blob = msBuilder.getBlob('text/javascript')
    } else {
        blob = new Blob(blobParams, { type: 'text/javascript' })
    }

    var blobURL = window.URL.createObjectURL(blob)
    worker = new Worker(blobURL)
    worker._workerID = workerID
    putIn(worker, workerID, _activePools)
    return worker
}

WorkerManager.releaseWorker = function (worker) {
    if (worker._workerID === undefined) {
        throw new Error('Worker is not created by WorkerManager or mutated')
    }
    remove(worker, worker._workerID, _activePools)
}

export { WorkerManager }
