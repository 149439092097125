
/**
 * Event sistemin olduğu, kendi debug methodlarını barındıran, çoğu objenin türertildiği class.
 * Dinamik bir event sistemi barındırmakta. Class için toString() methodu ile class adını basmakta.
 * Class türetildiğinde, super çağırılmalıdır. Objeye <b>uid</b> (unique id ) atanır. Bu uniq ID'nin ezilmesi tavsiye edilmez.
 * @author Selim Abidin <ysabidin@gmail.com>
 * @class Base
 * @constructor
 */
function Base () {
    this._events = {}
    this.baseClassName = 'base'
    this.uid = generateBaseID()
}

/**
 * @property {int} idCounter
 * @static
 * @readonly
 */

/**
 * Generate an unique ID
 * @return {String} new Unique ID
 * @memberof Base
 * @private
 * @return {string} uniqID
 */
function generateBaseID () {
    if (!Base.idCounter) Base.idCounter = 0// Math.floor(Base.idCounter = Math.random() * 999);
    return 'bo' + Base.idCounter++
}

/**
 * Class ismini döner.
 * @method getClassName
 * @return {string}
 * @memberof PanoGL
 */
Base.prototype.getClassName = function () {
    var con = this.constructor
    var name = con.name
    if (!name) {
        name = con.toString().match(/^function\s*([^\s(]+)/)[1]
    }
    return name
}

Base.prototype.toString = function (obj) {
    return '[' + this.getClassName() + ' Object]'
}

/**
 * Objeye herhangi bir event ekler.</br>
 * @example
 *
 * function myFunction(eventData){
 *
 * }
 *
 *  baseInstanceObject.addEvent("OnMouseClick", null, myFunction);
 *
 *
 * @method addEvent
 * @param {string} eventType Event tipi belirlenir. Örn. "OnComplete"
 * @param {Object} context methodun hangi scope alanında çalışması gerektiği belirtilir. bind yerine kullanılabilir.
 * @param {function} callBack Call back fonksiyonu.
 * @memberof Base
 */
Base.prototype.addEvent = function (eventType, context, callBack) {
    if (typeof eventType === 'string' && typeof context === 'function') {
        if (!this._events) this._events = {}

        if (this._events[eventType] === undefined) {
            this._events[eventType] = []
        }

        var _callback = context // if second param is function, then it is callback
        this._events[eventType].push(new Event(_callback, null, eventType))
    } else {
        if (eventType === undefined || eventType === null) {
            console.error('Event tipi null olamaz.')
            return
        }

        if (!this._events) this._events = {}

        if (!this._events[eventType]) {
            this._events[eventType] = []
        }

        if (!callBack) {
            this.error('Callback fonksiyonu null olamaz!')
        }
        this._events[eventType].push(new Event(callBack, context, eventType))
    }
}

/**
 * Objeye eklenmiş eventı siler.
 * @example
 * <b>Örnek : </b></br>
 * <code>
 * <pre>
 *   baseInstanceObject.removeEvent("OnMouseClick", myFunction);
 * </pre>
 * </code>
 * @method removeEvent
 * @param {string} eventType Event tipi belirlenir. Örn. "OnComplete"
 * @param {function} callBack Call back fonksiyonu.
 * @memberof Base
 */
Base.prototype.removeEvent = function (eventType, callBack) {
    if (typeof eventType !== 'string') {
        throw new Error('Event type should be a string')
    }

    if (typeof callBack !== 'function') {
        throw new Error('Callback should be a function')
    }

    var arr = this._events[eventType]

    if (!arr) return false

    var isRemoved = 0
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].callBack === callBack) {
            arr.splice(i, 1)
            --i
            if (i < 0) i = 0
            isRemoved++
        }
    }

    return isRemoved
}

Base.prototype.removeEventWithContext = function (eventType, context, callBack) {
    if (!this._events) this._events = {}

    var arr = this._events[eventType]

    if (!arr) return

    for (var i = 0; i < arr.length; i++) {
        if (arr[i].callBack === callBack && arr[i].context === context) {
            arr.splice(i, 1)
            --i
            if (i < 0) i = 0
        }
    }
}

/**
 * Eklenmiş event için callback fonksiyonları tetikler ve onllara event objesini çevirir.
 * <b>Örnek : </b></br>
 * <code>
 * <pre>
 * var eventObject = {type:"OnMouseClick", userData:{}}
 * baseInstanceObject.throwEvent(, myFunction);
 * </pre>
 * </code>
 * @method throwEvent
 * @param {Object} eventObject içinde "type" özelliği barındıran bir object olmak zorundadır.
 * @return {void} event eklenmemiş ise null değer döner. Diğer durumlarda void.
 * @memberof Base
 */
Base.prototype.throwEvent = function (eventObject) {
    if (!this._events) {
        return null
    }
    var eventType = eventObject.type
    var arr = this._events[eventType]
    if (arr === undefined) return null

    var _temp = arr.concat()

    eventObject.currentTarget = this

    for (var i = 0; i < _temp.length; i++) {
        if (_temp[i].context) {
            _temp[i].callBack.apply(_temp[i].context, [eventObject])
        } else {
            _temp[i].callBack(eventObject)
        }
    }
}

/**
 * Event tipine göre herhangi bir eventın varlığını kontrol eder.
 * @method hasEvent
 * @param {Object} eventType içinde "type" özelliği barındıran bir object olmak zorundadır.
 * @return {boolean}
 * @memberof Base
 */
Base.prototype.hasEvent = function (eventType) {
    if (!this._events) {
        return false
    }

    var arr = this._events[eventType]
    if (arr === undefined) return false

    return true
}

function Event (callBack, context, type) {
    this.type = type
    this.context = context
    this.callBack = callBack
}

export { Base }
